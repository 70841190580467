import produce from "immer";
import _ from "lodash";

import { Types } from "./types";

const MIN_PROGRESS_PERCENT_TO_APPROVE = 89;
/**
 * Reducer
 */

const INITIAL_STATE = {
  loading: false,
  data: [],
  selected: {
    id: null,
    name: "",
    shortDescription: "",
    longDescription: "",
    type: "",
    typeContent: "about",
    url: "",
    vimeo_url: "",
    parentCode: null,
    parentName: null,
    tags: [],
    knowMore: [],
    cover: null,
  },
  actionPlayVideo: null,
  navigationContent: {
    prev: null,
    next: null
  },
  progress: {
    countObjects: null,
    countFinishedObjects: null,
    loading: false
  },
  idDisciplineProgress: null,
  playerPosition: null,
  userRequestSeekPlayer: false,
  autoplayNextVideos: true,
  muted: false,
  lastSyncedGradeDiscipline: "",
  error: null,
  called: false,
  selectedDiscipline: "",
  warningDisplayed: false,
  refreshCourse: false,
  selectedCourseToShowProgress: null,
  defaultSelectedCourse: 0,
  loadingCertificate: false,
  certificates: {},
  redirectToTenantData: null,
  isFetchSearchLogRequestAction: 'stop',
  notShowModal: false,
  courseSession: null,
};

export default function course(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.RESET_COURSE:
      return {
        ...INITIAL_STATE,
        autoplayNextVideos: state.autoplayNextVideos,
        selected: {
          id: null,
          name: "",
          parentCode: null,
          parentName: null,
          shortDescription: "",
          longDescription: "",
          type: "",
          typeContent: "about",
          url: "",
          vimeo_url: "",
          tags: [],
          knowMore: [],
          error: null,
          showKnowMore: false,
          cover: null,
        },
        actionPlayVideo: null,
        loadingCertificate: false,
        certificates: {},
        courseSession: null
      };
    case Types.SET_ACTION_PLAY_VIDEO:
      return { ...state, actionPlayVideo: action.payload };
    case Types.GET_COURSE_REQUEST:
      return { ...state, loading: true, error: null, data: action.payload.course};
    case Types.SET_SHOW_MODAL: {
      const { data } = state;
      const newData = {...data}
      newData.showModalCourse = false
      return { ...state, showModalMessageCourse: action.payload, data: newData  }
    }
    case Types.GET_COURSE_SUCCESS: {
      const countObjects = _.get(action, 'payload.data.countObjects', null);
      const countFinishedObjects = _.get(action, 'payload.data.countFinishedObjects', null);
      const newCertificates = _.get(state, 'certificates');
      const newData = _.get(action, 'payload.data');
      const type = _.get(newData, 'type');
      newCertificates[newData.code] = {
        certificate: newData.certificate,
        courseApprovalMinGrade: newData.courseApprovalMinGrade,
        courseGradeStatus: newData.courseGradeStatus,
        gradeAverage: newData.gradeAverage
      };

      if(type === 'TRAIL') {
        newData.children.forEach(child => {
          if(child.type === 'COURSE') {
            newCertificates[child.code] = {
              certificate: child.certificate,
              courseApprovalMinGrade: child.courseApprovalMinGrade,
              courseGradeStatus: child.courseGradeStatus,
              gradeAverage: child.gradeAverage
            };
          }
        });
      }

      return {
        ...state,
        loading: false,
        called: true,
        data: newData,
        error: null,
        warningDisplayed: false,
        progress: {
          ...state.progress,
          countObjects,
          countFinishedObjects,
        },
        loadingCertificate: false,
        certificates: newCertificates
      };
    }
    case Types.GET_COURSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: {
          status: action.payload.error.status,
          error: action.payload.error.data.message
        },
        loadingCertificate: false,
        certificates: {}
      };
    case Types.SET_DISCIPLINE_SELECTED:
      return {
        ...state,
        selectedDiscipline: action.payload.data
      };
    case Types.DEFINE_DISCIPLINE_AS_SYNCHRONIZED:
      return produce(state, draft => {
        const idDiscipline = action.payload;

        const disciplineIndex = draft.data.disciplines.findIndex(
          item => item.idDiscipline === idDiscipline
        );
        // eslint-disable-next-line no-param-reassign
        draft.data.disciplines[disciplineIndex].synchronized = true;
      });
    case Types.SET_LAST_SYNCED_GRADE_DISCIPLINE:
      return {
        ...state,
        lastSyncedGradeDiscipline: action.payload
      };
    case Types.SET_COURSE_SELECTED: {
      const objectCrCode = _.get(action, 'payload.code', null);
      const childrenCourse = _.get(state, 'data.children', []);
      const findObjectParent = childrenCourse
      .filter(item => item.type !== "ABOUT")
      .filter(item => {
        const objectFound = item.children.find(itemChild => itemChild.code === objectCrCode);
        if(objectFound){
          return objectFound;
        }
        return null
      });

      return {
        ...state,
        selected: {
          id: _.get(action, "payload.id", ""),
          code: _.get(action, "payload.code", ""),
          name: _.get(action, "payload.name", ""),
          parentCode: _.get(findObjectParent, '0.code', null),
          parentName: _.get(findObjectParent, '0.name', null),
          contentProgress: _.get(action, "payload.contentProgress", ""),
          hierarchy: _.get(action, "payload.hierarchy", ""),
          longDescription: _.get(action, "payload.longDescription", ""),
          shortDescription: _.get(action, "payload.shortDescription", ""),
          typeContent: _.get(action, "payload.media.type", ""),
          type: _.get(action, "payload.media.type", ""),
          url: _.get(action, "payload.media.path", ""),
          vimeo_url: _.get(action, "payload.media.path_vimeo"),
          cover: _.get(action, "payload.cover.path", ""),
          warningDisplayed: false,
          posicao: _.get(action, "payload.currentPosition", ""),
          tags: _.get(action, "payload.tags", ""),
          courseCode: _.get(action, "payload.courseCode", ""),
          knowMore: _.get(action, "payload.knowMore", []),
          loadingCertificate: false, 
          showKnowMore: _.get(action, "payload.showKnowMore", false),
        },
        error: null,
      };
    }
    case Types.SET_NAVIGATION_CONTENT:
      return {
        ...state,
        navigationContent: action.payload.data
      };
    case Types.SET_PLAYER_POSITION: {
      let page;
      const { selected, data } = state
      const currentPage = _.get(selected,"contentProgress.ox_status.nx_value", 1)
      const nextPage = action.payload.data;
      if(nextPage === 1 && currentPage > 1) {
        page = currentPage;
      } else {
        page = nextPage
      }
      const newData = {...data}
      const updatedSelect = {...selected}
      _.set(updatedSelect, 'contentProgress.ox_status.nx_value', page)
      if(data.type === 'TRAIL') {
        data.children.forEach((course, courseIndex) => {
          if(course.code === selected.courseCode) {
            let itemIndex;
            course.children.forEach((uc, ucIndex) => {
              const item = uc.children.find((object, index) => {
                if(object.code === selected.code) {
                  itemIndex = index;
                  return true
                }
                return false
              });
              if(item) {
                _.set(item, 'contentProgress.ox_status.nx_value', page)
                newData.children[courseIndex].children[ucIndex].children[itemIndex] = item;
              }
            })
          }
        })
      }

      if(data.type === 'COURSE') {
        data.children.forEach((uc, ucIndex) => {
          if(uc.code === selected.parentCode) {
            let itemIndex;
            const item = uc.children.find((object, index) => {
              if(object.code === selected.code) {
                itemIndex = index;
                return true
              }
              return false
            });
            if(item) {
              _.set(item, 'contentProgress.ox_status.nx_value', page)
              newData.children[ucIndex].children[itemIndex] = item;
            }
          }
        });
      }

      return {
        ...state,
        data: newData,
        playerPosition: page,
      };
    }
    case Types.SET_USER_REQUEST_SEEK_PLAYER:
        return {
          ...state,
          userRequestSeekPlayer: !state.userRequestSeekPlayer
        };
    case Types.REFRESH_COURSE:
        return {
          ...state,
          refreshCourse: action.payload
        };
    case Types.SET_COURSE_TO_SHOW_PROGRESS: {
      const newCertificates = _.get(state, 'certificates');
      const course = action.payload;
      if(course && course.certificate) {
        newCertificates[course.code] = {
          certificate: course.certificate,
          courseApprovalMinGrade: course.courseApprovalMinGrade,
          courseGradeStatus: course.courseGradeStatus,
          gradeAverage: course.gradeAverage
        };
      }
      
      return {
        ...state,
        selectedCourseToShowProgress: action.payload,
        certificates: newCertificates
      };
    }
    case Types.GET_COURSE_PROGRESS_REQUEST:
      return {
        ...state,
        progress: {
          ...state.progress,
          loading: true
        }
      };
    case Types.GET_COURSE_PROGRESS_SUCCESS:
      return {
        ...state,
        progress: {
          loading: false,
          countObjects: action.payload.countObjects,
          countFinishedObjects: action.payload.countFinishedObjects
        }
      };
    case Types.SET_AUTOPLAY_VIDEOS:
      return {
        ...state,
        autoplayNextVideos: action.payload.autoplay
      };
    case Types.SET_VIDEO_MUTED:
      return {
        ...state,
        muted: action.payload.muted
      };
    case Types.SET_DISCIPLINE_PROGRESS:
      return {
        ...state,
        idDisciplineProgress: action.payload.idDiscipline
      };
    case Types.SET_WANING_DISPLAYED:
      return {
        ...state,
        warningDisplayed: action.payload.warningDisplayed
      };
    case Types.RESET_ERROR_COURSE:
      return {
        ...state,
        error: null
      };
    case Types.INCREMENT_COURSE_PROGRESS: {
      const countObjects = _.get(state, 'progress.countObjects', null);
      const countFinishedObjects = _.get(state, 'progress.countFinishedObjects', null);
      const selected = _.get(state, 'selected');
      const data = _.get(state, 'data')
      
      const {status} = action.payload;
      const newData = {...data};

      if(data.type === 'TRAIL') {
        data.children.forEach((course, courseIndex) => {
          if(course.code === selected.courseCode) {
            let itemIndex;
            course.children.forEach((uc, ucIndex) => {
              const item = uc.children.find((object, index) => {
                if(object.code === selected.code) {
                  itemIndex = index;
                  return true
                }
                return false
              });
              if(item) {
                _.set(item, 'contentProgress.ox_status.wk_status', status)
                if(status === 'DONE') {
                  _.set(item, 'contentProgress.ox_status.nx_value', 0)
                }
                newData.children[courseIndex].children[ucIndex].children[itemIndex] = item;
                if(status) {
                  newData.children[courseIndex].countFinishedObjects += 1
                }
              }
            })
          }
        })
      }

      if(data.type === 'COURSE') {
        data.children.forEach((uc, ucIndex) => {
          if(uc.code === selected.parentCode) {
            let itemIndex;
            const item = uc.children.find((object, index) => {
              if(object.code === selected.code) {
                itemIndex = index;
                return true
              }
              return false
            });
            if(item) {
              _.set(item, 'contentProgress.ox_status.wk_status', status)
              if(status === 'DONE') {
                _.set(item, 'contentProgress.ox_status.nx_value', 0)
              }
              newData.children[ucIndex].children[itemIndex] = item;
              if(status) {
                newData.countFinishedObjects += 1
              }
            }
          }
        });
      }
  
      if(newData.type === 'COURSE' && newData.courseCertificationIsProgress) {
        const countDoneTotal = newData.countFinishedObjects || 0;
        const countTotal = newData.countObjects || 0;
        const countDoneTotalPercent =
          (countDoneTotal * 100) / (countTotal || 1);
        if(countDoneTotalPercent >= MIN_PROGRESS_PERCENT_TO_APPROVE) {
          window.location.reload()
        }
      }
      
      if(status) {
        return {
          ...state,
          progress: {
            ...state.progress,
            countObjects,
            countFinishedObjects: countFinishedObjects + 1,
          },
          data: newData,
        }
      }
      
      return {
        ...state,
        progress: {
          ...state.progress,
          countObjects,
          countFinishedObjects: countFinishedObjects + 1,
        },
        loadingCertificate: false,
      }
    }
    case Types.SET_DEFAULT_SELECTED_COURSE: {
      return {
        ...state,
        defaultSelectedCourse: action.payload.value
      }
    }
    case Types.GET_COURSE_CERTIFICATE_REQUEST: {
      return {
        ...state,
        loadingCertificate: true
      }
    }
    case Types.GET_COURSE_CERTIFICATE_SUCCESS: {
      const { courseCode, response } = action.payload;
      const newCertificates = _.get(state, 'certificates');
      newCertificates[courseCode] = response;
            
      return {
        ...state,
        certificates: newCertificates,
        loadingCertificate: false,
      }
    }

    case Types.GET_COURSE_CERTIFICATE_FAILURE: {
      return {
        ...state,
        loadingCertificate: false,
      }
    }

    case Types.REDIRECT_TO_TENANT: {
      return {
        ...state,
        redirectToTenantData: action.payload
      }
    }

    case Types.FETCH_SEARCH_LOG_REQUEST: {
      return {
        ...state,
        isFetchSearchLogRequestAction: action.payload
      }
    }

    case Types.SET_COURSE_SESSION:
      return { ...state, courseSession: action.payload };

    default:
      return state;
  }
}
