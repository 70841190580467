import { Types } from "./types";
/**
 * Actions
 */
export const Creators = {
  getCourseRequest: (course, idProfile, refreshCourse = false) => ({
    type: Types.GET_COURSE_REQUEST,
    payload: { course, idProfile, refreshCourse }
  }),
  reloadCourse: (idCourse, idProfile) => ({
    type: Types.RELOAD_COURSE_DATA,
    payload: { idCourse, idProfile }
  }),
  getCourseSuccess: data => ({
    type: Types.GET_COURSE_SUCCESS,
    payload: { data }
  }),
  getCourseFailure: error => ({
    type: Types.GET_COURSE_FAILURE,
    payload: { error }
  }),

  resetErrorCourse: () => ({
    type: Types.RESET_ERROR_COURSE
  }),

  setCourseSelected: data => {
    return {
      type: Types.SET_COURSE_SELECTED,
      payload: data
    };
  },

  setShowModal: value => {
    return {
      type: Types.SET_SHOW_MODAL,
      payload: value
    };
  },

  setLastSyncedGradeDiscipline: idDiscipline => ({
    type: Types.SET_LAST_SYNCED_GRADE_DISCIPLINE,
    payload: idDiscipline
  }),

  setDisciplineSelected: data => ({
    type: Types.SET_DISCIPLINE_SELECTED,
    payload: { data }
  }),

  syncGradeByDiscipline: idDiscipline => ({
    type: Types.SYNC_GRADE_BY_DISCIPLINE,
    payload: idDiscipline
  }),

  defineDisciplineAsSynchronizedAction: payload => ({
    type: Types.DEFINE_DISCIPLINE_AS_SYNCHRONIZED,
    payload
  }),

  setNavigationContent: data => ({
    type: Types.SET_NAVIGATION_CONTENT,
    payload: { data }
  }),

  setPlayerPosition: data => ({
    type: Types.SET_PLAYER_POSITION,
    payload: { data }
  }),

  setUserRequestSeekPlayer: () => ({
    type: Types.SET_USER_REQUEST_SEEK_PLAYER
  }),

  setRefreshCourse: data => {
    return {
      type: Types.REFRESH_COURSE,
      payload: data
    };
  },

  setCourseToShowProgress: data => {
    return {
      type: Types.SET_COURSE_TO_SHOW_PROGRESS,
      payload: data
    };
  },

  getCourseProgressRequest: idCourse => ({
    type: Types.GET_COURSE_PROGRESS_REQUEST,
    payload: { idCourse }
  }),

  getCourseProgressSuccess: ({ countObjects, countFinishedObjects }) => ({
    type: Types.GET_COURSE_PROGRESS_SUCCESS,
    payload: { countObjects, countFinishedObjects }
  }),

  setDisciplineProgress: idDiscipline => ({
    type: Types.SET_DISCIPLINE_PROGRESS,
    payload: { idDiscipline }
  }),

  setAutoplayVideos: autoplay => {
    return {
      type: Types.SET_AUTOPLAY_VIDEOS,
      payload: { autoplay }
    };
  },

  setVideoMuted: muted => {
    return {
      type: Types.SET_VIDEO_MUTED,
      payload: { muted }
    };
  },

  setWarningDisplayed: warningDisplayed => {
    return {
      type: Types.SET_WANING_DISPLAYED,
      payload: { warningDisplayed }
    };
  },

  setActionPlayVideo: data => {
    return {
      type: Types.SET_ACTION_PLAY_VIDEO,
      payload: data
    };
  },

  resetCourse: () => ({
    type: Types.RESET_COURSE
  }),

  incrementCourseProgress: (status = null) => ({
    type: Types.INCREMENT_COURSE_PROGRESS,
    payload: {
      status
    }
  }),

  fetchSimulatedGradeAction: (
    idProfile,
    idDiscipline,
    idContent,
    idChildren
  ) => ({
    type: Types.FETCH_SIMULATED_GRADES_BY_UC,
    payload: {
      idProfile,
      idDiscipline,
      idContent,
      idChildren
      // request: { url: `/getgradesimuc/${idContent}/${idProfile}`, method: 'GET' },
    }
  }),

  fetchFinalGradeByDisciplineAction: (ra, idDiscipline) => ({
    type: "@COURSE/FETCH_FINAL_MEDIA_BY_DISCIPLINE",
    payload: {
      ra,
      idDiscipline
    }
  }),

  setDefaulfSelectedCourse: (value) => ({
    type: Types.SET_DEFAULT_SELECTED_COURSE,
    payload: {
      value
    }
  }),

  getCourseCertificateRequest: (courseCode) => ({
    type: Types.GET_COURSE_CERTIFICATE_REQUEST,
    payload: { courseCode }
  }),

  getCourseCertificateSuccess: ({ courseCode, response = {} }) => ({
    type: Types.GET_COURSE_CERTIFICATE_SUCCESS,
    payload: { courseCode, response }
  }),

  getCourseCertificateFailure: () => ({
    type: Types.GET_COURSE_CERTIFICATE_FAILURE,
  }),

  redirectToTenant: (data) => ({
    type: Types.REDIRECT_TO_TENANT,
    payload: data
  }),

  fetchSearchLogRequest: (data) => ({
    type: Types.FETCH_SEARCH_LOG_REQUEST,
    payload: data
  }),

  setCourseSession: session => ({
    type: Types.SET_COURSE_SESSION,
    payload: session
  }),
};
