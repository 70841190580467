import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Documento from './Media/Documento';
import SwitchableVideoPlayer from './Media/SwitchableVideoPlayer'
import Praticar from './Media/Praticar';
import Forum from './Media/Forum';
import Scorm from './Media/Scorm';
import Podcast from './Media/Podcast';
import RedirectMessage from './Media/RedirectMessage';
import SaibaMais from './Extra/SaibaMais';

import { Creators as CourseActions } from '../../store/ducks/newCourse/actions';

const Media = ({ setCourseSelected, setVideoMuted, course, selected }) => {
  const [openScorm, setOpenScorm] = useState(false);

  function getContentByCode(content, targetContent) {
    if (content.code === targetContent) {
      return content;
    }

    if (content.children) {
      for (const childNode of content.children) {
        const desiredNode = getContentByCode(childNode, targetContent);
        if (desiredNode) return desiredNode;
      }
    }

    return false;
  }

  function openScormCallback() {
    setOpenScorm(true);
  }

  function closeScorm() {
    setOpenScorm(false);
  }

  useEffect(() => {
    if (course.lastDispatchedContentCode) {
      const lastDispatchedContent = getContentByCode(
        course,
        course.lastDispatchedContentCode
      );

      if (
        lastDispatchedContent &&
        lastDispatchedContent.contentProgress &&
        lastDispatchedContent.contentProgress.ox_status
      ) {
        setCourseSelected(lastDispatchedContent);
      }
    }
  }, []);

  let media;

  switch (selected.type) {
    case 'VIDEO':
    case 'VOD':
      media = <SwitchableVideoPlayer />;
      break;
    case 'PODCAST':
      media = <Podcast />;
      break;
    case 'PDF':
      media = <Documento />;
      break;
    case 'ANGLO_URL':
    case 'REDIRECT':
      media = <RedirectMessage />;
      break;
    case 'VMP':
      media = <Praticar />;
      break;
    case 'FOR':
      media = <Forum />;
      break;
    case 'SCORM':
    case 'HTML':
    case 'TEL':
      media = <Scorm openScorm={openScorm} onOpen={closeScorm} />;
      break;

    default:
      media = null;
      break;
  }

  return (
    <div className={`col-lg-${selected.type === 'SCORM' ? '12' : '8'}`}>
      <div className="row">
        <div className="container-fluid" style={{ height: '100%' }}>
          {media}
          {selected.type === 'TEL' && (
            <div
              className="card"
              style={{
                fontWeight: 'bold',
                fontSize: '1.1em',
                margin: '.7rem 0rem',
                border: '1px solid #ddd'
              }}
            >
              <button
                type="button"
                style={{ margin: 15, alignSelf: 'center' }}
                className="btn btn-blue btn-FontWeight6"
                onClick={openScormCallback}
              >
                Abrir
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  selected: state.newCourse.selected,
  course: state.newCourse.data
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(CourseActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Media);
