import styled from 'styled-components'

export const TenantImageLink = styled.a`
  img#tenant-logo {
    width: auto !important;
    max-width: none !important;
  }
`;

export const TitleModal = styled.h2`
  color: #6d6d6d;
`;

export const TenancyListContainer = styled.div`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
    width: 100%;
`;

export const TenancyItem = styled.div`
    text-align: center;
    margin: 10px 20px;
    cursor: pointer;
    display: block;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background: 0 0;
    border: 0;
`;

export const TenancyName = styled.p`
  color: #dbdbdb
`;

export const TenancyIcon = styled.div`
    background: gray;
    padding: 10;
    width: 200px;
    height: 150px;
    border: 1px solid #eeeeee;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-width: 3px;
    border-style: solid;
    &:hover {
        opacity: 0.85
      }
`;

export const TenancyDescription = styled.div`
    width: 100%;
    text-align: center;
`;

export const ProfileDescription = styled.div`
    margin-left: 20px;
`;

export const ProfileContainer = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
`;

export const ProfileLink = styled.a`
    color: #444;
    &:hover {
        color: #777
      }
`;

export const ProfileItem = styled.p`
    font-size: 17px
`;

export const ProfileList = styled.div`
    
`;

export const BackLink = styled.a`
    color: #27aae1
`;

export const ContainerLoading = styled.a`
    color: #444
`;