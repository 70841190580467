/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UserActions } from '../../store/ducks/user';
import { SocialActions } from '../../store/ducks/social';
import { Creators as CourseActions } from '../../store/ducks/course/actions';
import { Creators as NewCourseActions } from '../../store/ducks/newCourse/actions';
import './Busca.css';
import NavBarSuperior from '../NavBarSuperior/NavBarSuperior';
import CardNovoCurso from './CardNovoCurso';
import CardLmsAttachment from './CardLmsAttachment';
import CardUser from './CardUser';

import ToggleButton from "../Carreira/ToggleButton"

import { getInterests } from "../../business/webbff";

import { showOldCourses } from '../../utils/functions';
import CardBook from './CardBook';

import Loading from '../Loading/Loading';

import Wrapcard from './WrapCard';
import { gateway } from '../../utils/api';
import history from '../../utils/history';

const filtros = [
  { type: 'filterNewCourse', name: 'Cursos' },
  {
    type: 'filterLmsCourseAttachment',
    name: 'Objetos de aprendizagem'
  },
  { type: 'filterUsers', name: 'Onlearners' },
  { type: 'filterBooks', name: 'Livros' }
];

const initialState = {
  searchText: '',
  page: 1,
  doneSearch: false,
  pageCourse: 1,

  resultUsers: [],
  pageUsers: 1,
  totalPagesUsers: 1,
  loadingUsers: false,
  filterUsers: false,
  doneUsers: false,

  resultGroups: [],
  pageGroup: 1,
  totalPagesGroups: 1,
  loadingGroups: false,
  filterGroups: false,
  doneGroups: false,

  resultNewGroups: [],
  pageNewGroups: 1,
  totalPagesNewGroups: 1,
  loadingNewGroups: false,
  filterNewGroups: false,
  doneNewGroups: false,

  resultBooks: [],
  pageBooks: 1,
  totalPagesBooks: 1,
  loadingBooks: false,
  filterBooks: false,
  doneBooks: false,

  resultNewCourses: [],
  pageNewCourse: 1,
  totalPagesNewCourse: 1,
  loadingNewCourse: false,
  filterNewCourse: false,
  doneNewCourse: false,

  // talks
  resultTalksCourses: [],
  pageTalksCourses: 1,
  totalPagesTalksCourses: 1,
  loadingTalksCourses: false,
  filterTalksCourses: false,
  doneTalksCourses: false,

  // live class
  resultLiveClassCourses: [],
  pageLiveClassCourses: 1,
  totalPagesLiveClassCourses: 1,
  loadingLiveClassCourses: false,
  filterLiveClassCourses: false,
  doneLiveClassCourses: false,

  // webnars
  resultWebnars: [],
  pageWebnars: 1,
  totalPagesWebnars: 1,
  loadingWebnars: false,
  filterWebnars: false,
  doneWebnars: false,

   // Trilha mba
   resultTrailsMba: [],
   pageTrailsMba: 1,
   totalPagesTrailsMba: 1,
   loadingTrailsMba: false,
   filterTrailsMba: false,
   doneTrailsMba: false,

   // Trilha
   resultTrails: [],
   pageTrails: 1,
   totalPagesTrails: 1,
   loadingTrails: false,
   filterTrails: false,
   doneTrails: false,

  resultLmsCourseAttachments: [],
  pageLmsCourseAttachment: 1,
  totalPagesLmsCourseAttachment: 1,
  loadingLmsCourseAttachment: false,
  filterLmsCourseAttachment: false,
  doneLmsCourseAttachment: false,

  loadingGeneralSearch: false,

  searchLog: {},
  requestProfilesResult: {
    ok: false
  },
  requestAttachmentsResult: {
    ok: false
  },
  requestGeneralResult: {
    ok: false
  },

  dispacthUpdateSearchLogRequest: true,
  selectedItem: null,
  interests: [],
  interestSelected: null
};

class Busca extends Component {
  filter = [];

  state = {
    ...initialState,
    tags: [],
    selectedTags: []
  };

  componentWillUpdate(nextProps, nextState) {
    // if (nextProps.location.state.query !== this.props.location.state.query) {
    //   this.updateSearch(nextProps.location.state.query);
    // }

    const { location } = nextProps;
    const locationCurrent = this.props.location;
    const paramsNew = new URLSearchParams(location.search);
    const paramsCurrent = new URLSearchParams(locationCurrent.search);
    const sNew = paramsNew.get("s");
    const sCurrent = paramsCurrent.get("s");
    if(sNew !== sCurrent){
      this.updateSearch(sNew);
    }

    if (
      nextProps.isFetchSearchLogRequestAction !==
      this.props.isFetchSearchLogRequestAction
    ) {
      if (nextProps.isFetchSearchLogRequestAction === 'start') {
        this.fetchRequestSearchLog();
      }
    }

    const { isFetchSearchLogRequestAction } = this.props;

    if (
      nextState.searchLog &&
      nextState.searchLog._id &&
      nextState.requestAttachmentsResult &&
      nextState.requestAttachmentsResult.ok &&
      nextState.requestProfilesResult &&
      nextState.requestProfilesResult.ok &&
      nextState.requestGeneralResult &&
      nextState.requestGeneralResult.ok &&
      isFetchSearchLogRequestAction === 'stop'
    ) {
      this.updateSearchLog();
    }
  }

  updateSearchLog = () => {
    const { fetchSearchLogRequest } = this.props;
    fetchSearchLogRequest('start');
  };

  fetchRequestSearchLog = async () => {
    const {
      searchLog,
      requestGeneralResult,
      requestProfilesResult,
      requestAttachmentsResult
    } = this.state;
    const formattedSearchLog = {
      ...searchLog,
      ...requestGeneralResult,
      ...requestProfilesResult,
      ...requestAttachmentsResult
    };
    delete formattedSearchLog.ok;
    const updatedSearchLog = await this.updateSearchLogInDb(formattedSearchLog);

    const { fetchSearchLogRequest } = this.props;
    if (updatedSearchLog) {
      fetchSearchLogRequest('stop');
      this.setState({
        searchLog: updatedSearchLog,
        requestGeneralResult: {
          ...requestGeneralResult,
          ok: false
        },
        requestProfilesResult: {
          ...requestProfilesResult,
          ok: false
        },
        requestAttachmentsResult: {
          ...requestAttachmentsResult,
          ok: false
        }
      });
    }
  };

  selectedItemAndUpdateSearchLog = async ({
    item,
    path,
    object,
    isBook = false
  }) => {
    const { history } = this.props;
    const { searchLog } = this.state;
    searchLog.selected_item = item;
    this.updateSearchLogInDb(searchLog);
    if (isBook) {
      window.open(`${path}`, '_blank');
    } else if (path) {
      history.push(
        {
          pathname: path
        },
        {
          object
        }
      );
    }
  };

  updateSearchLogInDb = async searchLog => {
    const updateSearchLog = await gateway
      .put(`applicationlogs/searchlogs/${searchLog._id}`, searchLog)
      .catch(() => null);

    if (updateSearchLog) {
      return updateSearchLog;
    }

    return searchLog;
  };

  handleSearchResults = response => {
    switch (response.type) {
      case 'lmsCourse':
        this.setState({
          loadingNewCourse: false,
          totalPagesNewCourse: response.totalPages,
          resultNewCourses: response.docs || [],
          doneNewCourse: true
        });
        break;
      case 'lmsTalks':
        this.setState({
          loadingTalksCourses: false,
          totalPagesTalksCourses: response.totalPages,
          resultTalksCourses: response.docs || [],
          doneTalksCourses: true
        });
        break;
      case 'lmsLiveClass':
        this.setState({
          loadingLiveClassCourses: false,
          totalPagesLiveClassCourses: response.totalPages,
          resultLiveClassCourses: response.docs || [],
          doneLiveClassCourses: true
        });
        break;
      case 'lmsWebnars':
        this.setState({
          loadingWebnars: false,
          totalPagesWebnars: response.totalPages,
          resultWebnars: response.docs || [],
          doneWebnars: true
        });
        break;
      case 'lmsTrailsMba':
        this.setState({
          loadingTrailsMba: false,
          totalPagesTrailsMba: response.totalPages,
          resultTrailsMba: response.docs || [],
          doneTrailsMba: true
        });
        break;
      case 'lmsTrails':
        this.setState({
          loadingTrails: false,
          totalPagesTrails: response.totalPages,
          resultTrails: response.docs || [],
          doneTrails: true
        });
        break;
      case 'profile':
        this.setState({
          loadingUsers: false,
          totalPagesUsers: response.totalPages,
          resultUsers: response.returnObject || [],
          doneUsers: true
        });
        break;
      case 'book':
        this.setState({
          loadingBooks: false,
          totalPagesBooks: response.totalPages,
          resultBooks: response.books || [],
          doneBooks: true
        });
        break;
      case 'lmsCourseAttachment':
        this.setState({
          loadingLmsCourseAttachment: false,
          totalPagesLmsCourseAttachment: response.totalPages,
          resultLmsCourseAttachments: response.docs || [],
          doneLmsCourseAttachment: true
        });
        break;
      default:
        break;
    }
  };

  componentDidMount = async () => {
    const {
      location
    } = this.props;
    this.shouldShowOldCourses = await showOldCourses();
    const params = new URLSearchParams(location.search);
    const searchText = params.get("s");
    this.setState({ searchText }, this.search);

    this.loadTags();
    this.loadInterests();
  };

  loadTags = async () => {
    try {
      const url =
        '/content/tag/list?ox_specific.wk_type=TAG&ox_standard.ox_classification.qk_tags=SEARCH';

      const response = await gateway.get(url);

      const formattedTags = response.map(item => ({
        value: _.get(item, 'ox_standard.ox_identification.cr_code', ''),
        label: _.get(item, 'ox_standard.qi_presentation.0.ws_name', '')
      }));
      this.setState({ tags: formattedTags });
    } catch (error) {}
  };

  loadInterests = async () => {
    try {
      const interests = await getInterests();
      this.setState({ interests });
    } catch (error) {}
  };

  toggleInterest = (type) => {
    const { interestSelected } = this.state;
    if(interestSelected === type) {
      this.setState({ interestSelected: null });
      const {
        location
      } = this.props;
      const params = new URLSearchParams(location.search);
      const searchText = params.get("s");
      this.updateSearch(searchText);
    } else {
      this.setState({ interestSelected: type });
      this.updateSearch(type);
    }
  };


  updateSearch = query => {
    const { interestSelected, interests } = this.state;
    this.setState({ 
      ...initialState,
      searchText: query,
      interestSelected: query,
      interests
    }, this.search);
  };

  addRef = node => {
    this.filter = [...this.filter, node];
  };

  filterChanged = e => {
    const { id, checked } = e.target;
    this.setState({ [id]: checked });
  };

  removeFilter = (filtro, id) => {
    this.setState({ [filtro.type]: false });
    this.filter[id].checked = false;
  };

  reset = () => {
    this.setState(() => ({ ...initialState }));
  };

  calculateNumberOfCards = () => {
    const wrapContent = document.getElementById('card-body');
    const widthWrap = _.get(wrapContent, 'offsetWidth', 200);
    const widthCard = 241;
    return parseInt(widthWrap / widthCard, 10);
  };

  search = async e => {
    if (e) e.preventDefault();
    const size = this.calculateNumberOfCards();
    const { searchText, selectedTags } = this.state;

    let body = {
      tag: searchText
    };

    if (selectedTags && selectedTags.length > 0) {
      body = {
        ...body,
        tags: selectedTags.map(el => el.value)
      };
    }
    const { interestSelected, interests } = this.state;

    this.setState({
      ...initialState,
      searchText,
      loadingUsers: true,
      loadingCourse: true,
      loadingNewCourse: true,
      loadingLmsCourseAttachment: true,
      loadingGroups: true,
      loadingAttachments: true,
      loadingBooks: true,
      loadingNewGroups: true,
      loadingGeneralSearch: true,
      requestGeneralResult: {
        ok: false
      },
      requestAttachmentsResult: {
        ok: false
      },
      requestProfilesResult: {
        ok: false
      },
      interestSelected,
      interests
    });

    const crCodeProfile = localStorage.getItem('crCodeProfile');
    const idProfile = localStorage.getItem('idProfile');
    const tenant = localStorage.getItem('tenant');
   
    let userReference = idProfile;
    if(!userReference || userReference === 'undefined') {
      userReference = crCodeProfile
    }

    const searchLog = {
      user_reference: userReference,
      tenant,
      search_data: body
    };

    const createdSearchLog = await gateway
      .post('/applicationlogs/searchlogs/', searchLog)
      .catch(() => null);
    if (createdSearchLog) {
      this.setState({
        searchLog: createdSearchLog
      });
    }

    const { pageNewCourse } = this.state;
    const page = pageNewCourse;
    const url = `webbff/lit_app_web/search/generalSearch?page=${page}&size=${size}`;
    const company = localStorage.getItem('company') || ' ';
    if (filtros && filtros.length > 0) {
      filtros.forEach(filtro => {
        const element = document.getElementById(filtro.type);
        if (element) {
          element.checked = false;
        }
      });
    }
    if (company.toUpperCase() !== 'ANGLO') {
      this.doUserSearchStart();
      try {
        const response = await gateway.post(url, body);
        const requestGeneralResult = {};
        response.forEach(item => {
          switch (item.type) {
            case 'profile': {
              const searchResultProfiles = item.returnObject || [];
              requestGeneralResult.search_result_profiles = searchResultProfiles.map(
                item => ({
                  page,
                  ...item
                })
              );
              break;
            }
            case 'book': {
              const searchResultBooks = item.books || [];
              requestGeneralResult.search_result_books = searchResultBooks.map(
                item => ({
                  page,
                  ...item
                })
              );
              break;
            }

            case 'lmsCourse': {
              const searchResultLmsCourses = item.docs || [];
              requestGeneralResult.search_result_lmsCourses = searchResultLmsCourses.map(
                item => ({
                  page,
                  ...item
                })
              );
              break;
            }

            case 'lmsCourseAttachment': {
              const searchResultLmsCourseAttachments = item.docs || [];
              requestGeneralResult.search_result_lmsCourseAttachments = searchResultLmsCourseAttachments.map(
                item => ({
                  page,
                  ...item
                })
              );
              break;
            }

            default:
              break;
          }
        });
        requestGeneralResult.ok = true;
        this.setState({
          requestGeneralResult
        });
        response.map(item => this.handleSearchResults(item));
      } catch (error) {
        console.log(error);
      } finally {
        this.setState({
          loadingGeneralSearch: false
        });
      }
    } else {
      this.doNewCourseSearch();
      this.doLmsCourseAttachmentSearch();
    }
  };

  doUserSearchStart = async () => {
    const { pageUsers, searchText } = this.state;
    const body = {
      tag: searchText
    };
    const size = this.calculateNumberOfCards();
    const url = `webbff/lit_app_web/search/generalSearch?page=${pageUsers}&size=${size}&lane=profiles`;
    const response = await gateway.post(url, body);

    let resultUsers = response.returnObject || [];
    resultUsers = resultUsers.map(item => ({
      page: pageUsers,
      ...item
    }));

    this.setState({
      loadingUsers: false,
      totalPagesUsers: response.totalPages,
      resultUsers,
      doneUsers: true,
      requestProfilesResult: {
        ok: true
      }
    });
  };

  doUserSearch = async () => {
    const { pageUsers, searchText, searchLog } = this.state;
    const body = {
      tag: searchText
    };

    const size = this.calculateNumberOfCards();
    const url = `webbff/lit_app_web/search/generalSearch?page=${pageUsers}&size=${size}&lane=profiles`;
    const response = await gateway.post(url, body);

    let resultUsers = response.returnObject || [];
    resultUsers = resultUsers.map(item => ({
      page: pageUsers,
      ...item
    }));

    const searchResultProfiles = searchLog.search_result_profiles || [];
    this.setState({
      loadingUsers: false,
      totalPagesUsers: response.totalPages,
      resultUsers,
      doneUsers: true,
      searchLog: {
        ...searchLog,
        search_result_profiles: [...searchResultProfiles, ...resultUsers]
      }
    });
  };

  doGroupSearch = async () => {
    const { pageGroup, searchText, searchLog } = this.state;
    const body = {
      tag: searchText
    };
    const size = this.calculateNumberOfCards();
    const url = `webbff/lit_app_web/search/generalSearch?page=${pageGroup}&size=${size}&lane=groups`;
    const response = await gateway.post(url, body);
    let resultGroups = response.returnObject || [];
    resultGroups = resultGroups.map(item => ({ page: pageGroup, ...item }));
    const searchResultGroups = searchLog.search_result_groups || [];
    this.setState({
      loadingGroups: false,
      totalPagesGroups: response.totalPages,
      resultGroups,
      doneGroups: true,
      searchLog: {
        ...searchLog,
        search_result_groups: [...searchResultGroups, ...resultGroups]
      }
    });
  };

  doNewGroupsSearch = async () => {
    const size = this.calculateNumberOfCards();
    const { pageNewGroups, searchText, searchLog } = this.state;
    const body = {
      tag: searchText
    };

    const url = `webbff/lit_app_web/search/generalSearch?page=${pageNewGroups}&size=${size}&lane=newGroups`;
    const response = await gateway.post(url, body);
    const { groups, totalPages } = response;

    let resultNewGroups = groups || [];
    resultNewGroups = resultNewGroups.map(item => ({
      page: pageNewGroups,
      ...item
    }));

    const searchResultNewGroups = searchLog.search_result_newGroups || [];

    this.setState({
      loadingNewGroups: false,
      totalPagesNewGroups: totalPages,
      resultNewGroups,
      doneNewGroups: true,
      searchLog: {
        ...searchLog,
        search_result_newGroups: [...searchResultNewGroups, ...resultNewGroups]
      }
    });
  };

  doBooksSearch = async () => {
    const size = this.calculateNumberOfCards();
    const { pageBooks, searchText, searchLog } = this.state;
    const body = {
      tag: searchText
    };
    const url = `webbff/lit_app_web/search/generalSearch?page=${pageBooks}&size=${size}&lane=books`;
    const response = await gateway.post(url, body);
    const { books, totalPages } = response;
    let resultBooks = books || [];
    resultBooks = resultBooks.map(item => ({ page: pageBooks, ...item }));
    const searchResultBooks = searchLog.search_result_books || [];
    this.setState({
      loadingBooks: false,
      totalPagesBooks: totalPages,
      resultBooks,
      doneBooks: true,
      searchLog: {
        ...searchLog,
        search_result_books: [...searchResultBooks, ...resultBooks]
      }
    });
  };

  doNewCourseSearch = async () => {
    const { pageNewCourse = 1, searchText, searchLog } = this.state;

    const body = {
      tag: searchText, 
      tags: ['CURSOS'],
      laneReferName: 'lmsCourse'
    };
    const size = this.calculateNumberOfCards();
    const url = `webbff/lit_app_web/search/generalSearch?&page=${pageNewCourse}&size=${size}&lane=searchCourses`;
    const response = await gateway.post(url, body);
    let resultNewCourses = response.docs || [];

    resultNewCourses = resultNewCourses.map(item => ({
      page: pageNewCourse,
      ...item
    }));

    const searchResultLmsCourses = searchLog.search_result_lmsCourses || [];

    this.setState({
      loadingNewCourse: false,
      totalPagesNewCourse: response.totalPages,
      resultNewCourses,
      doneNewCourse: true,
      searchLog: {
        ...searchLog,
        search_result_lmsCourses: [
          ...searchResultLmsCourses,
          ...resultNewCourses
        ]
      }
    });
  };

  doTalks = async () => {
    const { pageTalksCourses = 1, searchText, searchLog } = this.state;

    const body = {
      tag: searchText, 
      tags: ['TALKS'],
      laneReferName: 'lmsTalks'
    };
    const size = this.calculateNumberOfCards();
    const url = `webbff/lit_app_web/search/generalSearch?&page=${pageTalksCourses}&size=${size}&lane=searchCourses`;
    const response = await gateway.post(url, body);
    let resultTalksCourses = response.docs || [];

    resultTalksCourses = resultTalksCourses.map(item => ({
      page: pageTalksCourses,
      ...item
    }));

    const searchResultTalksCourses = searchLog.search_result_lmsTalks || [];

    this.setState({
      loadingTalksCourses: false,
      totalPagesTalksCourses: response.totalPages,
      resultTalksCourses,
      doneTalksCourses: true,
      searchLog: {
        ...searchLog,
        search_result_lmsTalks: [
          ...searchResultTalksCourses,
          ...resultTalksCourses
        ]
      }
    });
  };

  doLiveClass = async () => {
    const { pageLiveClassCourses = 1, searchText, searchLog } = this.state;

    const body = {
      tag: searchText, 
      tags: ['AULAS ON-LINE'],
      laneReferName: 'lmsLiveClass'
    };
    const size = this.calculateNumberOfCards();
    const url = `webbff/lit_app_web/search/generalSearch?&page=${pageLiveClassCourses}&size=${size}&lane=searchCourses`;
    const response = await gateway.post(url, body);
    let resultLiveClassCourses = response.docs || [];

    resultLiveClassCourses = resultLiveClassCourses.map(item => ({
      page: pageLiveClassCourses,
      ...item
    }));

    const searchResultLiveClassCourses = searchLog.search_result_lmsLiveClass || [];

    this.setState({
      loadingLiveClassCourses: false,
      totalPagesLiveClassCourses: response.totalPages,
      resultLiveClassCourses,
      doneLiveClassCourses: true,
      searchLog: {
        ...searchLog,
        search_result_lmsLiveClass: [
          ...searchResultLiveClassCourses,
          ...resultLiveClassCourses
        ]
      }
    });
  };

  doWebnar = async () => {
    const { pageWebnars = 1, searchText, searchLog } = this.state;

    const body = {
      tag: searchText, 
      tags: ['WEBINARS'],
      laneReferName: 'lmsWebnars',
    };
    const size = this.calculateNumberOfCards();
    const url = `webbff/lit_app_web/search/generalSearch?&page=${pageWebnars}&size=${size}&lane=searchCourses`;
    const response = await gateway.post(url, body);
    let resultWebnars = response.docs || [];

    resultWebnars = resultWebnars.map(item => ({
      page: pageWebnars,
      ...item
    }));

    const searchResultWebnars = searchLog.search_result_lmsWebnars || [];

    this.setState({
      loadingWebnars: false,
      totalPagesWebnars: response.totalPages,
      resultWebnars,
      doneWebnars: true,
      searchLog: {
        ...searchLog,
        search_result_lmsWebnars: [
          ...searchResultWebnars,
          ...resultWebnars
        ]
      }
    });
  };

  doTrailMba = async () => {
    const { pageTrailsMba = 1, searchText, searchLog } = this.state;

    const body = {
      tag: searchText, 
      tags: ['TRILHA MBA'],
      laneReferName: 'lmsTrailsMba'
    };
    const size = this.calculateNumberOfCards();
    const url = `webbff/lit_app_web/search/generalSearch?&page=${pageTrailsMba}&size=${size}&lane=searchCourses`;
    const response = await gateway.post(url, body);
    let resultTrailsMba = response.docs || [];

    resultTrailsMba = resultTrailsMba.map(item => ({
      page: pageTrailsMba,
      ...item
    }));

    const searchResultTrailsMba = searchLog.search_result_lmsTrailMba || [];

    this.setState({
      loadingTrailsMba: false,
      totalPagesTrails: response.totalPages,
      resultTrailsMba,
      doneTrailsMba: true,
      searchLog: {
        ...searchLog,
        searchResultTrailMba: [
          ...searchResultTrailsMba,
          ...resultTrailsMba
        ]
      }
    });
  };

  doTrail = async () => {
    const { pageTrails = 1, searchText, searchLog } = this.state;

    const body = {
      tag: searchText, 
      tags: ['TRILHA'],
      laneReferName: 'lmsTrails'
    };
    const size = this.calculateNumberOfCards();
    const url = `webbff/lit_app_web/search/generalSearch?&page=${pageTrails}&size=${size}&lane=searchCourses`;
    const response = await gateway.post(url, body);
    let resultTrails = response.docs || [];

    resultTrails = resultTrails.map(item => ({
      page: pageTrails,
      ...item
    }));

    const searchResultTrail = searchLog.search_result_lmsTrai || [];

    this.setState({
      loadingTrails: false,
      totalPagesTrailsMba: response.totalPages,
      resultTrails,
      doneTrailsMba: true,
      searchLog: {
        ...searchLog,
        searchResultTrail: [
          ...searchResultTrail,
          ...resultTrails
        ]
      }
    });
  };

  doLmsCourseAttachmentSearch = async () => {
    const { pageLmsCourseAttachment, searchText, searchLog } = this.state;
    const body = {
      tag: searchText
    };
    const size = this.calculateNumberOfCards();
    const url = `webbff/lit_app_web/search/generalSearch?&page=${pageLmsCourseAttachment}&size=${size}&lane=newCoursesAttachments`;
    const response = await gateway.post(url, body);
    let resultLmsCourseAttachments = response.docs || [];
    resultLmsCourseAttachments = resultLmsCourseAttachments.map(item => ({
      page: pageLmsCourseAttachment,
      ...item
    }));
    const searchResultLmsCourseAttachments =
      searchLog.search_result_lmsCourseAttachments || [];
    this.setState({
      loadingLmsCourseAttachment: false,
      totalPagesLmsCourseAttachment: response.totalPages,
      resultLmsCourseAttachments,
      doneLmsCourseAttachment: true,
      searchLog: {
        ...searchLog,
        search_result_lmsCourseAttachments: [
          ...searchResultLmsCourseAttachments,
          ...resultLmsCourseAttachments
        ]
      }
    });
  };

  onPageChangedCourse = data => {
    const { pageCourse, searchText } = this.state;
    const text = searchText;
    const newPage = data.currentPage;
    if (newPage !== pageCourse) {
      this.setState(
        {
          loadingCourse: true,
          searchText: text,
          pageCourse: newPage
        },
        this.doCourseSearch
      );
    }
  };

  onPageChangedUsers = data => {
    const { pageUsers, searchText } = this.state;
    const text = searchText;
    const newPage = data.currentPage;
    if (newPage !== pageUsers) {
      this.setState(
        {
          loadingUsers: true,
          searchText: text,
          pageUsers: newPage
        },
        this.doUserSearch
      );
    }
  };

  onPageChangedGroups = data => {
    const { pageGroup, searchText } = this.state;
    const text = searchText;
    const newPage = data.currentPage;
    if (newPage !== pageGroup) {
      this.setState(
        {
          loadingGroups: true,
          searchText: text,
          pageGroup: newPage
        },
        this.doGroupSearch
      );
    }
  };

  onPageChangedBooks = data => {
    const { pageBooks, searchText } = this.state;
    const text = searchText;
    const newPage = data.currentPage;
    if (newPage !== pageBooks) {
      this.setState(
        {
          loadingBooks: true,
          searchText: text,
          pageBooks: newPage
        },
        this.doBooksSearch
      );
    }
  };

  onPageChangedNewGroups = data => {
    const { pageNewGroups, searchText } = this.state;
    const text = searchText;
    const newPage = data.currentPage;
    if (newPage !== pageNewGroups) {
      this.setState(
        {
          loadingNewGroups: true,
          searchText: text,
          pageNewGroups: newPage
        },
        this.doNewGroupsSearch
      );
    }
  };

  onPageChangedNewCourse = data => {
    const { pageNewCourse, searchText } = this.state;
    const text = searchText;
    const newPage = data.currentPage;
    if (newPage !== pageNewCourse) {
      this.setState(
        {
          loadingNewCourse: true,
          searchText: text,
          pageNewCourse: newPage
        },
        this.doNewCourseSearch
      );
    }
  };

  onPageChangedTalks = data => {
    const { pageTalksCourses, searchText } = this.state;
    const text = searchText;
    const newPage = data.currentPage;
    if (newPage !== pageTalksCourses) {
      this.setState(
        {
          loadingTalksCourses: true,
          searchText: text,
          pageTalksCourses: newPage
        },
        this.doTalks
      );
    }
  };

  onPageChangedLiveClass = data => {
    const { pageLiveClassCourses, searchText } = this.state;
    const text = searchText;
    const newPage = data.currentPage;
    if (newPage !== pageLiveClassCourses) {
      this.setState(
        {
          loadingTalksCourses: true,
          searchText: text,
          pageLiveClassCourses: newPage
        },
        this.doLiveClass
      );
    }
  };

  onPageChangedWebnar = data => {
    const { pageWebnars, searchText } = this.state;
    const text = searchText;
    const newPage = data.currentPage;
    if (newPage !== pageWebnars) {
      this.setState(
        {
          loadingTalksCourses: true,
          searchText: text,
          pageWebnars: newPage
        },
        this.doWebnar
      );
    }
  };

  onPageChangedTrailMba = data => {
    const { pageTrailsMba, searchText } = this.state;
    const text = searchText;
    const newPage = data.currentPage;
    if (newPage !== pageTrailsMba) {
      this.setState(
        {
          loadingTrailsMba: true,
          searchText: text,
          pageTrailsMba: newPage
        },
        this.doTrailMba
      );
    }
  };

  onPageChangedTrail = data => {
    const { pageTrails, searchText } = this.state;
    const text = searchText;
    const newPage = data.currentPage;
    if (newPage !== pageTrails) {
      this.setState(
        {
          loadingTrails: true,
          searchText: text,
          pageTrails: newPage
        },
        this.doTrail
      );
    }
  };

  onPageChangedLmsCourseAttachment = data => {
    const { pageLmsCourseAttachment, searchText } = this.state;
    const text = searchText;
    const newPage = data.currentPage;
    if (newPage !== pageLmsCourseAttachment) {
      this.setState(
        {
          loadingLmsCourseAttachment: true,
          searchText: text,
          pageLmsCourseAttachment: newPage
        },
        this.doLmsCourseAttachmentSearch
      );
    }
  };

  goToCourse = (showLock, course, modalRestrictLitPass) => {
    const { toggleModalRestrictLitPass } = this.props;

    const courseCode = _.get(course, 'id')
    const lockedCourse = _.get(course, 'lockedCourse')

    const { pageCourse } = this.state;
    const item = {
      id: courseCode,
      name: _.get(course, 'nameCourse'),
      type: _.get(course, 'grade'),
      page: pageCourse,
      private: lockedCourse
    };

    if (showLock) {
      if (modalRestrictLitPass) {
        toggleModalRestrictLitPass(true);
                
      }
      // history.push(`/apresentacao-do-curso/${courseCode}/${lockedCourse}`)
    } else {
      this.selectedItemAndUpdateSearchLog({
        item,
        path: `/curso/${course.id}`
      });
    }
  };

  goToNewCourse = (showLock, course, modalRestrictLitPass) => {
    const { toggleModalRestrictLitPass, toggleModalRestrict} = this.props;
    const { pageNewCourse } = this.state;

    const item = {
      id: _.get(course, 'idCourse'),
      name: _.get(course, 'curso'),
      type: 'Curso',
      page: pageNewCourse,
      private: showLock
    };

    if (showLock) {
      if (modalRestrictLitPass) {
        toggleModalRestrictLitPass(true);
        return;
      }
      if (process.env.REACT_APP_MY_ENV !== "production") { 
        // history.push(`/apresentacao-do-curso/${course.idCourse}/${showLock}`)
      } else {
        toggleModalRestrict(true)
      }
    } else {
      const courseUrl = `/curso-novo/${course.idCourse}`
      this.selectedItemAndUpdateSearchLog({
        item,
        path: courseUrl
      });
    }
  };

  goToNewCoursebyAttachment = (
    showLock,
    course,
    objectCrCode,
    modalRestrictLitPass
  ) => {
    const { toggleModalRestrictLitPass } = this.props;
    const { pageLmsCourseAttachment } = this.state;

    const courseCode = _.get(course, 'courseCrCode');
    const lockedCourse = _.get(course, 'lockedCourse', false)

    const item = {
      id: _.get(course, 'crCode'),
      name: _.get(course, 'name'),
      type: 'Objeto',
      page: pageLmsCourseAttachment,
      private: _.get(course, 'lockedCourse', false)
    };

    if (showLock) {
      if (modalRestrictLitPass) {
        toggleModalRestrictLitPass(true);
        return;
      }
      history.push(`/apresentacao-do-curso/${courseCode}/${lockedCourse}`)
    } else {
      this.selectedItemAndUpdateSearchLog({
        item,
        path: `/curso-novo/${courseCode}`,
        object: objectCrCode
      });
    }
  };

  goToCourseSelect = attachemntSelect => {
    const { setCourseSelected } = this.props;
    const { pageAttchaments } = this.state;
    setCourseSelected(attachemntSelect);
    const item = {
      id: _.get(attachemntSelect, 'idAttachment'),
      name: _.get(attachemntSelect, 'attachmentName'),
      type: _.get(attachemntSelect, 'type'),
      page: pageAttchaments,
      private: _.get(attachemntSelect, 'lockedCourse', false)
    };

    this.selectedItemAndUpdateSearchLog({
      item,
      path: `/curso/${attachemntSelect.idCourse}`
    });
  };

  goToGroup = (enterSocial, grupo) => {
    const naRealUser = JSON.parse(localStorage.getItem('naRealUser'));
    const { toggleModalRestrict, toggleModalRestrictLitPass } = this.props;
    const { pageGroup } = this.state;

    const item = {
      id: _.get(grupo, 'id'),
      name: _.get(grupo, 'name'),
      type: 'Grupo',
      page: pageGroup,
      private: _.get(grupo, 'privacidade', false)
    };

    if (enterSocial) {
      this.selectedItemAndUpdateSearchLog({
        item,
        path: `/grupo/${grupo.id}`
      });
    } else {
      if (naRealUser) {
        toggleModalRestrict(true, 'group');
        return;
      }
      toggleModalRestrictLitPass(true, 'group');
    }
  };

  goToNewGroup = (enterSocial, grupo) => {
    const naRealUser = JSON.parse(localStorage.getItem('naRealUser'));
    const { toggleModalRestrict, toggleModalRestrictLitPass } = this.props;
    const { pageNewGroups } = this.state;
    const item = {
      id: _.get(grupo, 'groupCode'),
      name: _.get(grupo, 'name'),
      type: 'Grupo',
      page: pageNewGroups,
      private: _.get(grupo, 'privacy', false)
    };
    if (enterSocial) {
      this.selectedItemAndUpdateSearchLog({
        item,
        path: `/beta/social/socialGroup/${grupo.groupCode}`
      });
    } else {
      if (naRealUser) {
        toggleModalRestrict(true, 'group');
        return;
      }
      toggleModalRestrictLitPass(true, 'group');
    }
  };

  goToProfile = user => {
    const { pageUsers } = this.state;
    const linkProfile = user.role.includes('Aluno')
      ? `/perfilPublico/${user.id}`
      : `/perfilProfessor/${user.id}`;
    const item = {
      id: _.get(user, 'id'),
      name: _.get(user, 'name'),
      type: _.get(user, 'role'),
      page: pageUsers,
      private: _.get(user, 'lockedCourse', false)
    };
    this.selectedItemAndUpdateSearchLog({
      item,
      path: linkProfile
    });
  };

  goToBook = async (enterLibrary, book) => {
    const naRealUser = JSON.parse(localStorage.getItem('naRealUser'));
    const { toggleModalRestrict, toggleModalRestrictLitPass } = this.props;
    const { pageBooks } = this.state;
    const item = {
      id: _.get(book, 'cr_code'),
      name: _.get(book, 'ws_name'),
      type: 'Livro',
      page: pageBooks,
      private: _.get(book, 'lockedCourse', false)
    };

    if (enterLibrary && !naRealUser) {
      try {
        const url = `webbff/lit_app_web/home/library/${book}`;
        const response = await gateway.get(url);
        this.selectedItemAndUpdateSearchLog({
          item,
          path: response.url,
          isBook: true
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      if (naRealUser) {
        toggleModalRestrict(true, 'book');
        return;
      }
      toggleModalRestrictLitPass(true, 'book');
    }
  };

  filterEmpty = () => {
    const {
      filterCourse,
      filterNewCourse,
      filterLmsCourseAttachment,
      filterUsers,
      filterGroups,
      filterAttchaments,
      filterBooks,
      filterBooksBFF,
      filterNewGroups
    } = this.state;

    return (
      !filterCourse &&
      !filterNewCourse &&
      !filterLmsCourseAttachment &&
      !filterUsers &&
      !filterGroups &&
      !filterAttchaments &&
      !filterBooks &&
      !filterBooksBFF &&
      !filterNewGroups
    );
  };

  render() {
    const {
      searchText,
      filterNewCourse,
      pageNewCourse,
      resultNewCourses,
      resultTalksCourses,
      
      // talks
      loadingTalksCourses,
      doneTalksCourses,
      totalPagesTalksCourses,
      pageTalksCourses,
      filterTalksCourse,

      // live class
      loadingLiveClassCourses,
      doneLiveClassCourses,
      totalPagesLiveClassCourses,
      pageLiveClassCourses,
      filterLiveClassCourses,
      // webnars
      resultWebnars,
      loadingWebnars,
      doneWebnars,
      totalPagesWebnars,
      pageWebnars,
      filterWebnars,
      // trails
      resultTrails,
      loadingTrails,
      doneTrails,
      totalPagesTrails,
      pageTrails,
      filterTrails,
      resultLiveClassCourses,
      totalPagesNewCourse,
      loadingNewCourse,
      filterLmsCourseAttachment,
      pageLmsCourseAttachment,
      resultLmsCourseAttachments,
      totalPagesLmsCourseAttachment,
      loadingLmsCourseAttachment,
      resultUsers,
      totalPagesUsers,
      loadingUsers,
      filterUsers,
      pageUsers,
      resultBooks,
      totalPagesBooks,
      loadingBooks,
      filterBooks,
      pageBooks,
      doneNewCourse,
      doneLmsCourseAttachment,
      doneUsers,
      doneBooks,
      loadingGeneralSearch,
      interests,
      interestSelected
    } = this.state;

    const thisRef = this;
    const libraryUser = JSON.parse(localStorage.getItem('libraryUser'));

    return (
      <div>
        <NavBarSuperior searchText={searchText} />
        <div className="container-fluid" id="grupo">
          <div className="row">
            <div className="col-md-2">
              <div className="card">
                <div className="card-header">
                  <h4>Filtrar Exibição</h4>
                </div>
                <div className="card-body">
                  {filtros.map(filtro => {
                    return (
                      <div className="checkbox" key={filtro.type}>
                        <label>
                          <input
                            type="checkbox"
                            id={filtro.type}
                            ref={thisRef.addRef}
                            onChange={this.filterChanged}
                          />
                          <span className="cr">
                            <i className="cr-icon fa fa-check" />
                          </span>
                          {filtro.name}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-md-10" style={{ marginBottom: '2em' }}>
              <div
                  style={{ display: "flex", flexWrap: "wrap", marginBottom: '0'}}
                  className="grupo-interesse"
                  data-toggle="buttons"
                >
                  {interests.map((interest) => (
                    <ToggleButton
                      key={interest.idSuggestion}
                      id={`btn-${interest.idSuggestion}`}
                      idTrackSuggestion={interest.idSuggestion}
                      name={interest.nameSuggestion}
                      handleSelect={() =>
                        this.toggleInterest(interest.nameSuggestion)
                      }
                      grau={interestSelected === interest.nameSuggestion}
                    />  
                  ))}
              </div>
              {(loadingBooks ||
                loadingNewCourse ||
                loadingLmsCourseAttachment ||
                loadingGeneralSearch) && (
                <div style={{ margin: '0 auto' }}>
                  <Loading width={30} />
                </div>
              )}
                
              <div className="card">
                <div id="card-body" className="card-body" style={{ padding: '0'}}>
                  <div className="row">
                    <div className="col-md-12">
                      {filtros.map((filtro, index) => {
                        if (this.state[filtro.type]) {
                          return (
                            <span
                              key={filtro.type}
                              className="badge badge-pill badge-lit"
                            >
                              {filtro.name}
                              <a
                                role="button"
                                onClick={() => this.removeFilter(filtro, index)}
                              >
                                <i className="fa fa-times" />
                              </a>
                            </span>
                          );
                        }
                        return null;
                      })}
                    </div>
                  </div>

                  {(this.filterEmpty() || filterNewCourse) && (
                    resultNewCourses.length > 0 && <Wrapcard
                      loading={loadingNewCourse}
                      doneSearch={doneNewCourse}
                      name="Cursos"
                      onPageChanged={this.onPageChangedNewCourse}
                      resultLength={resultNewCourses.length}
                      totalPages={totalPagesNewCourse}
                      page={pageNewCourse}
                    >
                      <div className="wrap-content">
                        {resultNewCourses.map(result => (
                          <CardNovoCurso
                            goToCourse={this.goToNewCourse}
                            key={result.id}
                            result={result}
                          />
                        ))}
                      </div>
                    </Wrapcard>
                  )}

                  {(this.filterEmpty() || filterTalksCourse) && (
                    resultTalksCourses.length > 0 && <Wrapcard
                      loading={loadingTalksCourses}
                      doneSearch={doneTalksCourses}
                      name="Talks"
                      onPageChanged={this.onPageChangedTalks}
                      resultLength={resultTalksCourses.length}
                      totalPages={totalPagesTalksCourses}
                      page={pageTalksCourses}
                    >
                      <div className="wrap-content">
                        {resultTalksCourses.map(result => (
                          <CardNovoCurso
                            goToCourse={this.goToNewCourse}
                            key={result.id}
                            result={result}
                          />
                        ))}
                      </div>
                    </Wrapcard>
                  )}

                  {(this.filterEmpty() || filterLiveClassCourses) && (
                    resultLiveClassCourses.length > 0 && <Wrapcard
                      loading={loadingLiveClassCourses}
                      doneSearch={doneLiveClassCourses}
                      name="Aulas Online"
                      onPageChanged={this.onPageChangedLiveClass}
                      resultLength={resultLiveClassCourses.length}
                      totalPages={totalPagesLiveClassCourses}
                      page={pageLiveClassCourses}
                    >
                      <div className="wrap-content">
                        {resultLiveClassCourses.map(result => (
                          <CardNovoCurso
                            goToCourse={this.goToNewCourse}
                            key={result.id}
                            result={result}
                          />
                        ))}
                      </div>
                    </Wrapcard>
                  )}

                  {(this.filterEmpty() || filterWebnars) && (
                    resultWebnars.length > 0 && <Wrapcard
                      loading={loadingWebnars}
                      doneSearch={doneWebnars}
                      name="Webinar"
                      onPageChanged={this.onPageChangedWebnar}
                      resultLength={resultWebnars.length}
                      totalPages={totalPagesWebnars}
                      page={pageWebnars}
                    >
                      <div className="wrap-content">
                        {resultWebnars.map(result => (
                          <CardNovoCurso
                            goToCourse={this.goToNewCourse}
                            key={result.id}
                            result={result}
                          />
                        ))}
                      </div>
                    </Wrapcard>
                  )}

                  {(this.filterEmpty() || filterTrails) && (
                    resultTrails.length > 0 && <Wrapcard
                      loading={loadingTrails}
                      doneSearch={doneTrails}
                      name="Trilhas"
                      onPageChanged={this.onPageChangedTrail}
                      resultLength={resultTrails.length}
                      totalPages={totalPagesTrails}
                      page={pageTrails}
                    >
                      <div className="wrap-content">
                        {resultTrails.map(result => (
                          <CardNovoCurso
                            goToCourse={this.goToNewCourse}
                            key={result.id}
                            result={result}
                          />
                        ))}
                      </div>
                    </Wrapcard>
                  )}

                  {(this.filterEmpty() || filterLmsCourseAttachment) && (
                    resultLmsCourseAttachments.length > 0 && <Wrapcard
                      loading={loadingLmsCourseAttachment}
                      doneSearch={doneLmsCourseAttachment}
                      name="Objetos de Aprendizado"
                      onPageChanged={this.onPageChangedLmsCourseAttachment}
                      resultLength={resultLmsCourseAttachments.length}
                      totalPages={totalPagesLmsCourseAttachment}
                      page={pageLmsCourseAttachment}
                    >
                      <div className="wrap-content">
                        {resultLmsCourseAttachments.map(result => (
                          <CardLmsAttachment
                            goToCourse={this.goToNewCoursebyAttachment}
                            key={result.id}
                            result={result}
                          />
                        ))}
                      </div>
                    </Wrapcard>
                  )}

                  {(this.filterEmpty() || filterBooks) && (
                    resultBooks.length > 0 && libraryUser && <Wrapcard
                      loading={loadingBooks}
                      doneSearch={doneBooks}
                      name="Livros da biblioteca virtual"
                      onPageChanged={this.onPageChangedBooks}
                      resultLength={resultBooks.length}
                      totalPages={totalPagesBooks}
                      page={pageBooks}
                    >
                      <div className="wrap-content">
                        {resultBooks.map(result => (
                          <CardBook
                            goToBook={this.goToBook}
                            result={result}
                            key={result.id}
                          />
                        ))}
                      </div>
                    </Wrapcard>
                  )}

                  {(this.filterEmpty() || filterUsers) && (
                    resultUsers.length > 0 && <Wrapcard
                      loading={loadingUsers}
                      doneSearch={doneUsers}
                      name="Onlearners"
                      onPageChanged={this.onPageChangedUsers}
                      resultLength={resultUsers.length}
                      totalPages={totalPagesUsers}
                      page={pageUsers}
                    >
                      <div className="wrap-content">
                        {resultUsers.map(result => (
                          <CardUser
                            isRedirect
                            result={result}
                            key={result.id}
                            goToProfile={this.goToProfile}
                          />
                        ))}
                      </div>
                    </Wrapcard>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    pendingInvitations: state.social.pendingInvitations,
    isFetchSearchLogRequestAction: state.newCourse.isFetchSearchLogRequestAction
  };
};

const mergeActions = {
  ...SocialActions.pendingInvitationsActions,
  ...CourseActions,
  ...UserActions.ProfileActions,
  ...NewCourseActions
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(mergeActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Busca);
