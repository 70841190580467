/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { Creators as CourseActions } from '../../../store/ducks/newCourse/actions';

import SideBarMenuItemUC from '../SideBarMenuItemUC';

import * as Styles from './styles';
import AboutTrail from './AboutTrail';
import history from '../../../utils/history';

function SobreMenuItem({
  data,
  closeCourseProgress,
  defaultSelectedCourse,
  course,
  courseType,
  idCourseParam
}) {
  const selected = useSelector(state => state.newCourse.selected)
  const code = useMemo(() => {
    return _.get(data, 'cr_code', '');
  }, [data]);

  const aboutTitle = useMemo(() => {
    return _.get(data, 'name', '');
  }, [data]);

  const isTrail = useMemo(() => {
    const listContent = _.get(data, 'listContent', []);
    const checkIsTrail = listContent.filter(item => item.type === 'COURSE');
    return checkIsTrail.length > 0;
  }, [data]);

  const [codeRefer] = useState(Math.floor(Math.random() * 23443) + 1);
  const [selfAssessment, setSelfAssessment] = useState(null);

  const dispatch = useDispatch();

  const collapse = data.index === defaultSelectedCourse ? 'show' : '';
  const isActive = data.index === defaultSelectedCourse;

  const handleSelectedItem = (showKnowMore = false) => {
    const newData = {
      ...data,
      showKnowMore
    };
    dispatch(CourseActions.setCourseSelected(newData));

    if (closeCourseProgress) {
      closeCourseProgress();
    }
  };

  const [aboutItems, setAboutItems] = useState([
    {
      title: 'Saiba mais',
      action: () => handleSelectedItem(true),
      icon: 'plus'
    }
  ]);

  const openSelfAssessment = () => {
    const crCode = _.get(selfAssessment, 'cr_code', '');
    const redirectRoute = `${window.location.origin}/assessment-waiting-room?type=${selfAssessment.type}&course=${data.courseCode}&code=${crCode}&courseCrCode=${course.code}`;
    window.open(redirectRoute, '__blank');
  };

  const courseTypeToClass = useMemo(() => {
    return courseType ? courseType.toLowerCase() : '';
  }, [courseType]);

  useEffect(() => {
    const courseAssessment = _.get(course, 'assessments', []);
    if (courseAssessment.length > 0) {
      // Is course
      const findSelfAssessment = courseAssessment.find(
        item => item.type === 'SELF_ASSESSMENT'
      );
      setSelfAssessment(findSelfAssessment);
    } else {
      // Is Trail
      const children = _.get(course, 'children', []);
      const hasSelfAssessment = children.find(item => {
        if (item.assessments) {
          const findItem = item.assessments.find(
            itemChild => itemChild.type === 'SELF_ASSESSMENT'
          );
          return findItem;
        }
      });

      const assessments = _.get(hasSelfAssessment, 'assessments', []);
      const findSelfAssessment = assessments.find(
        item => item.type === 'SELF_ASSESSMENT'
      );
      setSelfAssessment(findSelfAssessment);
    }
  }, [course]);

  useEffect(() => {
    if (selfAssessment && !isTrail) {
      setAboutItems([
        ...aboutItems,
        {
          title: 'Personalizar o que aprender',
          action: () => openSelfAssessment(),
          icon: 'paint-brush'
        }
      ]);
    }
  }, [selfAssessment]);

  useEffect(() => {
    if (isTrail) {
      setAboutItems(aboutItems.filter(item => item.title !== 'Saiba mais'));
    }
  }, [isTrail]);

  useEffect(() => {
    const tags = _.get(data, 'tags', []);
    if (tags.includes('ABOUT')) {
      setAboutItems([
        {
          title: aboutTitle,
          action: () => {
            if (process.env.REACT_APP_MY_ENV !== "production") { 
              // history.push(`/apresentacao-do-curso/${idCourseParam}/false?from=about`)
            } else {
              handleSelectedItem();
            }
          },
          icon: 'home'
        },
        ...aboutItems
      ]);
    }
  }, [data]);

  return (
    <div id={`heading${codeRefer}`}>
      {isTrail && (
        <AboutTrail
          aboutTitle={aboutTitle || 'Sobre a Trilha'}
          handleSelectedItem={null}
          data-toggle="collapse"
          data-target={`#collapse${codeRefer}`}
          aria-expanded={isActive}
          aria-controls={`collapse${codeRefer}`}
          data-for="disciplina"
          data-tip="Minimize ou maximize o conteúdo e saiba o quanto do curso foi realizado"
          data={data}
        />
      )}

      {!isTrail && (
        <SideBarMenuItemUC
          tip="Conheça o objetivo do curso e os principais conceitos abordados"
          title={aboutTitle || 'Sobre o Curso'}
          hasCustomList
          icon="home"
          data-toggle="collapse"
          data-target={`#collapse${codeRefer}`}
          aria-expanded={isActive}
          aria-controls={`collapse${codeRefer}`}
          data-for="disciplina"
          data-tip="Minimize ou maximize o conteúdo e saiba o quanto do curso foi realizado"
        />
      )}

      <div
        id={`collapse${codeRefer}`}
        className={`collapse mb-3 ${collapse}`}
        aria-labelledby={`heading${codeRefer}`}
        data-course-type={courseTypeToClass}
        data-parent={isTrail ? '#accordionExample' : '#accordinUC'}
      >
        <Styles.Container id={code} available>
          {aboutItems.map((aboutItem, indexAbout) => (
            <Styles.ContentItem className="list-child" key={indexAbout}>
              <Styles.ContentItemChild
                title="Informações sobre o curso"
                role="button"
                onClick={aboutItem.action}
              >
                <Styles.ContainerIcon isApproved={false}>
                  <i className={`fa fa-${aboutItem.icon}`} />
                </Styles.ContainerIcon>
                <span className="info">{aboutItem.title}</span>
              </Styles.ContentItemChild>
            </Styles.ContentItem>
          ))}
        </Styles.Container>
      </div>
    </div>
  );
}

export default SobreMenuItem;
