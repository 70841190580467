import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import Tooltip from '@material-ui/core/Tooltip';
import * as Styles from './styles';

function ProgressBar({ data }) {
  const { countFinishedObjects = 0, countObjects = 0 } = data;
  const queryClient = useQueryClient();
  const idProfile = localStorage.getItem('idProfile');
  const tenant = localStorage.getItem("tenant") || "";
  const homeCrCode = localStorage.getItem("defaultHomeCrCode") || "";
  const selectedClassCrCode = localStorage.getItem("selectedClassCrCode") || "";
  
  useEffect(() => {
    const shouldRefetch = 
      [1, 2].includes(countObjects) && countFinishedObjects === 1 || 
      countObjects > 2 && countFinishedObjects === 3;
    if (shouldRefetch) {
      queryClient.refetchQueries(`homeData-${idProfile}-${tenant}-${homeCrCode}-${selectedClassCrCode}`);
    }
  }, [countFinishedObjects, countObjects, queryClient, idProfile]);

  return (
    <Tooltip title="Progresso" placement="top">
      <Styles.Container>
        <Styles.BarProgress
          className="mr-2"
          min={0}
          value={parseInt((countFinishedObjects * 100) / countObjects, 10) || 0}
          max={100}
        />
        {`${countFinishedObjects}/${countObjects}`}
      </Styles.Container>
    </Tooltip>
  );
}

export default ProgressBar;
