import React from 'react';
import { connect } from 'react-redux';

import Documento from '../Media/Documento';
import Professores from '../Professores';
import Video from '../Media/Video';
import Praticar from '../Media/Praticar';
import Forum from '../Media/Forum';
import ViewEditor from '../../shared/LitEditor/ViewEditor';
import * as Styles from './styles';

const Sobre = ({ instructors, selected, newCourse }) => {
  let media;
  if (selected.url) {
    switch (selected.type) {
      case 'VOD':
        media = <Video />;
        break;
      case 'PDF':
      case 'SOB':
        media = <Documento />;
        break;
      case 'VMP':
        media = <Praticar />;
        break;
      case 'FOR':
        media = <Forum />;
        break;
      default:
        media = null;
        break;
    }
  }

  return (
    <div className={`col-lg-${selected.type === 'SCORM' ? '12' : '8'}`}>
      {media}
      {media && (
        <div className="row">
          <div className="w-100 mx-3">
            <div className="pontos-comentados">
              <div className="container">
                <div className="row avatarVideo-curso" />
              </div>
            </div>
          </div>
        </div>
      )}
      <Professores data={instructors} />
    </div>
  );
};

const mapStateToProps = state => ({
  instructors: state.newCourse.data.instructors,
  newCourse: state.newCourse.data,
  selected: state.newCourse.selected
});

export default connect(mapStateToProps)(Sobre);
