import React, { useCallback, useMemo } from 'react';
import Img from 'react-image';
import _ from 'lodash';
import ContentLoader from 'react-content-loader';

import history from '../../utils/history';

import CardImgDefault from '../Home/CardImgDefault';
import { abbreviateText } from '../../utils/functions';

const MyLoader = () => (
  <ContentLoader
    height={180}
    width={325}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="0" y="0" rx="5" ry="5" width="400" height="400" />
  </ContentLoader>
);

export default function CardMyCourse({
  course,
  nome,
  fromPresentationCourse = false,
  progress = 0,
  handlePress = null
}) {
  const img = useMemo(() => {
    return _.get(course, 'courseImageUrl', '');
  }, [course]);

  const value = useMemo(() => {
    return (course.countFinishedObjects * 100) / course.countObjects;
  }, [course]);

  const openCourse = () => {
    if (handlePress && typeof handlePress === 'function') {
      handlePress();
    } else {
      history.push({ pathname: `/curso-novo/${course.idCourse}` });
    }
  };

  return (
    <div
      role="button"
      onClick={openCourse}
      className={`${
        fromPresentationCourse
          ? 'card-wrapper-card col-lg-4 col-md-4 col-sm-2 col-2 p-0 mr-3'
          : 'card-wrapper-p '
      }`}
      style={{
        display: 'inline-block',
        marginBottom: 40
      }}
    >
      <div className="card">
        {img ? (
          <Img
            src={img}
            onClick={openCourse}
            alt="ImageCourse"
            className="card-img-top"
            loader={<MyLoader />}
          />
        ) : (
          <CardImgDefault redirect={openCourse} course={course} />
        )}

        <div
          className="card-body"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <h5 className="card-title">
            {fromPresentationCourse ? abbreviateText(nome, 25) : nome}
          </h5>
          {course.countObjects && !fromPresentationCourse && (
            <div className="slider_title progressoCardMeuCurso">
              <p style={{ padding: 0, margin: 0 }}>
                {course.countFinishedObjects || 0}/{course.countObjects}
              </p>
              <progress min="0" value={parseInt(value, 10) || 0} max={100} />
            </div>
          )}
          {fromPresentationCourse && progress && (
            <div className="slider_title progressoCardMeuCurso">
              <p style={{ padding: 0, margin: 0 }}>{progress.progressItems}</p>
              <progress
                min="0"
                value={parseInt(progress.progressPercent, 10) || 0}
                max={100}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
