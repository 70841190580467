  import React, { memo, useState, useEffect, useCallback } from "react";
  import styled from "styled-components";
  import _ from "lodash";
  import Carousel from "react-multi-carousel";
  import "react-multi-carousel/lib/styles.css";
  import { gateway } from "../../../../utils/api";
  import ModalWarningView from "../../../Modals/ModalWarningView";
  import { openCallToAction } from '../../../../business/cta';


  const StyleNovidades = styled.div`
    margin-left: -15px;
    width: 102%;
    padding: 10px 10px 10px 20px;
  `;
  const ArrowButton = styled.button`
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: #333;
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 5;
  display: flex;
  align-items: center;
  opacity: 0.8; /* Adiciona opacidade para destaque */
  justify-content: center;
  ${(props) => (props.left ? "left: 10px;" : "right: 10px;")}

  &:hover {
    background-color: #e0e0e0;
  }
`;

const CustomLeftArrow = ({ onClick }) => (
  <ArrowButton left onClick={onClick}>
    &#10094;
  </ArrowButton>
);

const CustomRightArrow = ({ onClick }) => (
  <ArrowButton onClick={onClick}>
    &#10095;
  </ArrowButton>
);

  const HeaderContainer = styled.div`
    background: 
      linear-gradient(0deg, #4CC9FF, #4CC9FF),
      url('https://i.imgur.com/2UIJiCi.png') no-repeat;
    background-blend-mode: overlay;
    background-size: 30% 30%, 45%; 
    background-position: -80%, right 10% bottom 30px; 
    padding: 20px;
    color: #fff;
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 133, 199, 0.8); 
      z-index: -1;
    }
  `;

  const TitleSection = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
  `;

  const TitleImage = styled.img`
    width: 400px;
    height: 240px;
    margin-right: 20px;
    margin-left: 40px;
    border-radius: 8px;
    border: 2px solid #FFFFFF;
    object-fit: cover;
  `;

  const Title = styled.h1`
    font-size: 48px;
    margin: 0;
  `;

  const Participants = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  position: relative;

  & > div,
  & > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    margin-left: -13px; 
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ccc;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    border-right: 2px solid #4CC9FF; 
  }

  & > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  & > div:first-child, & > img:first-child {
    margin-left: 0;
  }
`;


  const AvisosSection = styled.div`
    margin-top: 20px;
    margin-left: 40px;
  `;

  const AvisoContainer = styled.div`
    position: relative; 
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: #FFFFFF;
    color: #3B3B3B;   
    border-radius: 8px;
    margin-right: 10px;
    max-width: 350px;
    min-width: 320px;
    height: 200px;
  `;

  const AvisoTexto = styled.div`
    font-size: 14px;
    color: #000000;
    font-weight: bold;
  `;

  const DateTexto = styled.div`
    font-size: 10px;
    color: #3B3B3B;
  `;

  const AvisoDescription = styled.div`
    font-size: 12px;
    color: #3B3B3B;
    margin-top: 5px;
    overflow: hidden;
  `;

  const VerMaisButton = styled.button`
    background-color: #ffcc00;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 12px;
    color: #000;
  `;

  const Tabs = styled.div`
    display: flex;
    margin-top: 20px;
    margin-left: 40px;
  `;

  const Tab = styled.div`
    padding: 10px 20px;
    cursor: pointer;
    color: #fff;
    font-size: 16px;
    font-weight: ${(props) => (props.active ? "bold" : "normal")};
    border-bottom: ${(props) => (props.active ? "2px solid #fff" : "none")};
  `;

  const MoreContent = styled.div`
    position: absolute;
    bottom: 15px;
    left: 15px;
    right: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `;

  const AvisoHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  `;

  const AvisoImage = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%; 
    margin-right: 10px; 
    border: 1px solid #ccc; 
  `;

  const AvisosHeader = styled.div`
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    color: #FFFFFF;
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 2px solid #646464;
  `;

  const AvisosCount = styled.span`
    font-size: 18px;
    color: #D3D3D3;
    margin-left: 8px;
  `;

  const AvisosTitle = styled.h2`
    margin: 0;
    font-size: 26px;
    color: #FFFFFF;
  `;

  const responsive = {
    veryDesktop: {
      breakpoint: { max: 3000, min: 1560 },
      items: 5,
      slidesToSlide: 2,
    },
    largDesktop: {
      breakpoint: { max: 1560, min: 1385 },
      items: 4,
      slidesToSlide: 2,
    },
    desktop: {
      breakpoint: { max: 1385, min: 1024 },
      items: 3,
      slidesToSlide: 2, 
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const Header = ({ turma, activePage, selectPage, students }) => {
    const [activeTab, setActiveTab] = useState("HOME");
    const [loadingWarnings, setLoadingWarnings] = useState(true);
    const [warningIndex, setWarningIndex] = useState(0)
    const [responseWarningsData, setResponseWarningsData] = useState();
    const [element, setElement] = useState({})
    const [openModalWarn, setOpenModalWarn] = useState(false)
    const onOpenModalWarn = () => setOpenModalWarn(true)
    const onCloseModalWarn = () => setOpenModalWarn(false);

    const tabs = [
      { label: "Feed da turma", page: "HOME" },
      // { label: "Calendário", page: "CALENDAR" },
      { label: "Notas e Faltas", page: "GRANDESANDABSENCES" },
      { label: "Alunos", page: "PARTICIPANTSLIST" },
    ];

    function removeHtmlTagsAndLimit(htmlString, limit = 200) {
      const textOnly = htmlString.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, ' ').trim();
      return textOnly.length > limit ? `${textOnly.substring(0, limit)}...` : textOnly;
    }
    const handleCallToAction = useCallback(cta => {
      const keys = Object.keys(cta);
      const values = Object.values(cta);
      const obj = {
        key: keys[0],
        value: values[0]
      };
      openCallToAction(obj);
    }, []);

    async function loadWarnings(code) {
      try {
        setLoadingWarnings(true);
        const url = `webbff/lit_app_web/classes/${code}/warnings`;
        const response = await gateway.get(url);
        return response;
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingWarnings(false);
      }
    }
    
    async function loadWarningsByTenant() {
      try {
        setLoadingWarnings(true);
        const url = `webbff/lit_app_web/warning`;
        const response = await gateway.get(url);
        return response;
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingWarnings(false);
      }
    }

    async function loadWarningsAll(code) {
      const [warnings, warningsByTenant] = await Promise.all([
        loadWarnings(code),
        loadWarningsByTenant()
      ])
      const docs1 = _.get(warnings, 'docs', []);
      const docs2 = _.get(warningsByTenant, 'docs', []);
      const docs = docs1.concat(docs2);
      const warningsWall = {...warnings, docs, totalDocs: docs.length}
      setResponseWarningsData(warningsWall);
    }
    const handlePage = () => {
      selectPage('PARTICIPANTSLIST')
    }

    const renderParticipant = (student, index) => {
      if (index === 5)  {
        return (
          <div role="button" key={index} style={{ position: 'relative', width: '40px', height: '40px', cursor: 'pointer' }} onClick={handlePage}>
            <img src={student.profileImage}
              alt={`Participante ${index}`}
              style={{ width: '100%', height: '100%', borderRadius: '50%'}}
       
            />
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: '#fff',
                fontSize: '18px',
                fontWeight: 'bold',
                cursor: 'pointer',
                backgroundColor: 'rgba(0, 0, 0, 0.4)',
              }}
            >
              ...
            </div>
          </div>
        );
    
      }
      if (student.profileImage) return <img key={index} src={student.profileImage} alt={`Participante ${index}`} />; 
      return <div key={index}>{student.name.slice(0, 2).toUpperCase()}</div>;
    };

    useEffect(() => {
      if (turma) loadWarningsAll(turma.organizationCode);
    }, [turma]);

    const openModalWarning = (el, index) => {
      setWarningIndex(index)
      setElement(el);
      onOpenModalWarn();
    }
    
    const fallbackUrl = "https://api.lit.com.br/api/media/file/download/672a1362e96a440012050f42.jpg";
    const fallbackName = "Sem Título";
    const quantityStudents = students ? students.length : 0;
    
    return (
      <HeaderContainer>
        <TitleSection>
          <TitleImage
            src={turma.cover || fallbackUrl}
            alt="Curso"
            onError={(e) => { e.currentTarget.src = fallbackUrl; }}
          />
          <div>
            <Title>{turma.name || fallbackName}</Title>
            <span>{quantityStudents} participantes</span>
            <Participants>{students.slice(0, 6).map((student, index) => renderParticipant(student, index))}</Participants>
          </div>
        </TitleSection>
{responseWarningsData &&         
      <AvisosSection>
          <AvisosHeader>
            <AvisosTitle>Avisos</AvisosTitle>
            <AvisosCount>({responseWarningsData && responseWarningsData.docs.length})</AvisosCount>
          </AvisosHeader>
          {responseWarningsData && (
            <StyleNovidades>
              <Carousel
                responsive={responsive}
                swipeable
                draggable
                showDots={false}
                infinite={false}
                keyBoardControl
                customTransition="all 0.5s"
                transitionDuration={500}
                containerClass="carousel-container"
                itemClass="carousel-item-padding-10-px"
                customLeftArrow={<CustomLeftArrow />}
                customRightArrow={<CustomRightArrow />}
                removeArrowOnDeviceType={["tablet", "mobile"]}
              >
                {responseWarningsData.docs.map((item, index) => (
                  <>
                                    <AvisoContainer key={item.name}>
                    <AvisoHeader>
                      <AvisoImage src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmvzijAToD4vUbylmjog2tmsATm6l7UkdgDA&s" alt="Ícone do Aviso" />
                      <AvisoTexto>{item.name}</AvisoTexto>
                    </AvisoHeader>
                    <AvisoDescription>{removeHtmlTagsAndLimit(item.message || '')}</AvisoDescription>
                    <MoreContent>
                      <DateTexto>{item.time}</DateTexto>
                      <VerMaisButton onClick={() => openModalWarning(item, index)}> Ver mais</VerMaisButton>
                    </MoreContent>

                  </AvisoContainer>

                  </>
                  
                ))}
                  <ModalWarningView 
                      openModal={openModalWarn}
                      onCloseModal={onCloseModalWarn}
                      onClick={() => handleCallToAction(responseWarningsData.docs[warningIndex])}
                      element={element}
                      onOrganization={false}
                      />
              </Carousel>
            </StyleNovidades>
          )}
        </AvisosSection>}


        <Tabs>
          {tabs.map((tab) => (
            <Tab key={tab.page} active={activePage === tab.page} onClick={() => selectPage(tab.page)}>
              {tab.label}
            </Tab>
          ))}
        </Tabs>


      </HeaderContainer>
    );
  };

  export default memo(Header);
