import styled from 'styled-components';

import ViewEditor from '../../../shared/ViewEditor';

export const Container = styled.div`
  font-family: Lato;
  height: 100%;
  min-height: 353px;
  overflow-x: hidden;
  @media (max-width: 710px) {
    flex-direction: column-reverse;
    min-height: auto;
  }
  ::-webkit-scrollbar {
    width: 5px;
    cursor: pointer;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  align-items: center;
  span {
    min-width: 100px;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    min-height: auto;
    justify-content: center;
    align-items: center;
    span {
      width: 100%;
      text-align: center;
    }
  }
`;

export const Status = styled.p`
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  margin-left: 20px;
  @media (max-width: 450px) {
    margin-left: 0;
  }

  color: ${props => {
      if (props.status.toUpperCase() === 'CORRECT') {
        return '#6ac259 !important';
      }

      if (props.status.toUpperCase() === 'PARTIALLY_CORRECT') {
        return '#6ac259 !important';
      }

      if (props.status.toUpperCase() === 'INCORRECT') {
        console.log('TUA AQUI');
        return '#e03b38 !important';
      }
      return '';
    }}
    svg {
    margin-right: 5px;
  }
`;

export const EnumCard = styled.div`
  width: 100%;
  padding: 5px 10px;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
`;

export const ContainerAnswerResult = styled.div`
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background: #f1f1f1;
  & + div {
    margin-top: 10px;
  }
`;

export const ContainerAlternative = styled.div`
  background-color: #fff;

  border: ${props => (props.noBorder ? 'none' : '1px')} solid #e0e0e0;
  border-color: ${props => {
    switch (props.borderColor) {
      case 'INCORRECT':
        return '#e03b38';
      case 'CORRECT':
        return '#6ac259';
      default:
        return '#E0E0E0';
    }
  }};
  border-radius: 5px;
  padding: ${props => (props.noBorder ? '0' : '5px')};
`;

export const Alternative = styled.div`
  background-color: transparent;

  display: flex;
  align-items: center;

  label {
    margin-bottom: 0 !important;
    display: flex;
  }

  .form-check {
    margin-left: 25px;
    margin-bottom: 0;
  }

  .form-check-label {
    text-align: left !important;
  }

  .form-check-input {
    margin-top: 0.35rem;

    input[type='checkbox'] {
      background-color: red;
      color: red;
    }

    input[type='checkbox']:disabled {
      background-color: #bebe;
      color: red;
    }
  }
`;

export const ContainerHint = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 5px 0;

  > p {
    margin: 0;
    padding: 10px;
    font-size: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    background: #fff;
    & + p {
      margin-left: 10px;
    }
  }

  #hint {
    font-weight: bold;
    font-size: 16px;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    > p {
      width: 100%;
      text-align: center;

      & + p {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
`;

export const HintViewEditor = styled(ViewEditor)`
  margin-left: 10px;
  padding: 10px 20px;
  font-size: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background: #fff;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const Button = styled.button`
  background: #00ace4;
  border-radius: 5px;
  width: ${props => (props.width ? `${props.width}px` : '110px')};
  height: ${props => (props.height ? `${props.height}px` : '30px')};
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin: 10px 10px;
  cursor: pointer;

  &:hover {
    background: #02c4fd;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
