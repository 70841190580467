import React, { PureComponent } from 'react';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { InView } from 'react-intersection-observer';
import { UserActions } from '../../store/ducks/user';
import { Creators as CourseActions } from '../../store/ducks/course/actions';
import PrivateCard from './PrivateCard';
import { API_URL_IMAGES } from '../../utils/constants';
import api from '../../utils/api';
import CardImgDefault from './CardImgDefault';
import history from '../../utils/history';


const { CancelToken } = axios;
const ReqSource = CancelToken.source();

const Loader = () => (
  <ContentLoader
    speed={2}
    width="100%"
    height={132}
    viewBox="0 0 400 132"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="26" rx="3" ry="3" width="200" height="6" />
    <rect x="0" y="56" rx="3" ry="3" width="100%" height="6" />
    <rect x="0" y="72" rx="3" ry="3" width="100%" height="6" />
    <rect x="0" y="88" rx="3" ry="3" width="100%" height="6" />
    <rect x="0" y="104" rx="3" ry="3" width="100%" height="6" />
    <rect x="0" y="120" rx="3" ry="3" width="100%" height="6" />
  </ContentLoader>
);

const ContainerLoader = styled.div`
  background: rgba(255, 255, 255, 0.3);
  padding: 10px;
`;

class CardCurso extends PureComponent {
  static contextTypes = {
    router: PropTypes.shape()
  };

  state = {
    idReaction: null,
    isLike: null,
    loadingLike: false,
    countObjects: 0,
    countFinishedObjects: 0,
    isNew: false,
    description: '',
    urlImg: ''
  };

  componentWillMount() {
    this.checkImgExists();
  }

  componentDidMount() {
    // this.getProgress();
    // this.findReaction();
  }

  componentWillUnmount() {
    ReqSource.cancel('requisição cancelada');
  }

  cuttingTextDescription = description => {
    const maxLength = 300;
    const hasSpace = description.slice(0, maxLength).lastIndexOf(' ') !== -1;
    if (description.length > maxLength) {
      return hasSpace
        ? `${description.slice(0, description.slice(0, maxLength).lastIndexOf(' '))}`
        : `${description.slice(0, maxLength)}`;
    }
    return description;
  };

  getProgress = () => {
    const { course } = this.props;
    api
      .get(`getGetCountingObjects/${localStorage.getItem('ra')}/${course.idCourse}/`, {
        CancelToken: ReqSource.token
      })
      .then(res => {
        this.setState({
          countObjects: res.countObjects,
          countFinishedObjects: res.countFinishedObjects
        });
      })
      .catch(err => console.log(err));
  };

  findReaction = () => {
    const { course } = this.props;
    const obj = {
      idReaction: null,
      idRA: localStorage.getItem('idRA'),
      totvsCourse: course.idCourse,
      bbChildren: '',
      spxAttachment: '',
      isLike: null
    };
    this.setState({
      isLike: null,
      idReaction: null
    });
    api
      .post('doFindReaction', obj, { CancelToken: ReqSource.token })
      .then(res => {
        this.setState({
          loadingLike: true,
          isLike: res.isLike,
          idReaction: res.idReaction
        });
      })
      .catch(err => console.log(err));
  };

  like = e => {
    e.stopPropagation();
    const { course } = this.props;
    const { idReaction } = this.state;
    const obj = {
      idReaction,
      idRA: localStorage.getItem('idRA'),
      totvsCourse: course.idCourse,
      bbChildren: '',
      spxAttachment: '',
      isLike: true
    };
    api
      .post('doReactionLike', obj, { CancelToken: ReqSource.token })
      .then(res => {
        this.setState({
          isLike: res.isLike,
          idReaction: res.idReaction
        });
        this.findReaction();
      })
      .catch(err => console.error(err));
  };

  dislike = e => {
    e.stopPropagation();
    const { course } = this.props;
    const { idReaction } = this.state;
    const obj = {
      idReaction,
      idRA: localStorage.getItem('idRA'),
      totvsCourse: course.idCourse,
      bbChildren: '',
      spxAttachment: '',
      isLike: false
    };
    api
      .post('doReactionDeslike', obj, { CancelToken: ReqSource.token })
      .then(res => {
        this.setState({
          isLike: res.isLike,
          idReaction: res.idReaction
        });
        this.findReaction();
      })
      .catch(err => console.error(err));
  };

  redirect = e => {
    const { router } = this.context;
    const { setCourseSelected, toggleModalRestrictLitPass, toggleModalRestrict, course, talks } = this.props;
    const litpass = localStorage.getItem('litpass') || false;
    const showLock = course.lockedCourse;
    const id = course.idCourse || course.attach.id;
    const classe = e.target.className.split(' ');
    if (showLock) {
      if (course.lockedCourse && JSON.parse(litpass)) {
        toggleModalRestrictLitPass(true);
      }  else {
        toggleModalRestrict(true);
      }
    } else if (!classe.includes('fa-thumbs-down') && !classe.includes('fa-thumbs-up')) {
      if (talks) {
        setCourseSelected(course.attach);
      }
        const { url = 'curso' } = this.props;
        console.log('here')
        router.history.replace({ pathname: `/${url}/${id}`, state: { talks } });
      
    }
  };

  toogleLane = e => {
    e.stopPropagation();
    const { course, refreshList } = this.props;
    const ra = localStorage.getItem('ra');
    const id = course.idCourse || course.attach.id;
    api
      .get(`toggleLaneVisibility/${ra}/${id}/`)
      .then(() => {
        refreshList();
      })
      .catch(error => console.log(error.response.data));
  };

  checkImgExists = () => {
    const { talks, course } = this.props;
    const { courseImageUrl } = course;
    let urlImg = '';
    if (courseImageUrl) {
      urlImg = courseImageUrl;
    } else {
      urlImg = talks
        ? `${API_URL_IMAGES}images/capa/video/${course.attach.id}/${course.thumbURL}`
        : `${API_URL_IMAGES}images/courses/${course.idCourse}.jpg`;
    }
    this.setState({ urlImg });
  };

  errorImgExists = () => {
    this.setState({ urlImg: null });
  };


  render() {
    const { course, progresso, talks, lastViews } = this.props;
    const {
      countObjects,
      countFinishedObjects,
      isNew,
      description,
      isLike,
      loadingLike,
      urlImg
    } = this.state;
    const freeUser = JSON.parse(localStorage.getItem('freeUser'));
    const showLock = (freeUser && !course.freeCourse) || course.lockedCourse;
    return (
      <div>
        <div className="slider_media">
          {showLock && <PrivateCard />}
          {urlImg ? (
    <InView
      triggerOnce
      threshold={0.1}
    >
      {({ inView, ref }) =>
        inView ? (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <img
            ref={ref}
            src={urlImg}
            alt={course.curso}
            style={{ width: '100%' }}
            onClick={this.redirect}
          />
        ) : (
          <div ref={ref}>
            <ContainerLoader>
              <Loader />
            </ContainerLoader>
          </div>
        )
      }
    </InView>
  ) : (
            <CardImgDefault redirect={this.redirect} course={course} />
          )}
          {isNew && (
            <div className="flag-new">
              <span>NOVO</span>
            </div>
          )}
        </div>
        <div role="presentation" className="slider_info" onClick={this.redirect}>
          <div className={`d-flex align-items-center ${showLock ? 'justify-content-between' : 'justify-content-end'}`}>
            {showLock && <PrivateCard />}
            {progresso && !lastViews && (
              <span role="button" onClick={this.toogleLane}>
                Remover da lane
              </span>
            )}
          </div>
          <div role="presentation" className="slider_title" onClick={this.redirect}>
            <span className="d-block font-weight-bold">
              {talks ? course.attach.attachmentName : course.curso}
            </span>
            <p className="description-course-card">
              {this.cuttingTextDescription(description)}
            </p>
            {progresso || course.progress && (
              <div className="barra-progress">
                {course.progress ? (
                  <>
                    <progress min="0" value={course.progress.progressPercent} max={100} />
                    <span>{course.progress.progressItems}</span>
                  </>
                ) : (
                  <>
                    <progress min="0" value={(countFinishedObjects * 100) / countObjects || 0} max={100} />
                    <span>{countFinishedObjects}/{countObjects}</span>
                  </>
                )}
                {countFinishedObjects > 0 && countFinishedObjects === countObjects && (
                  <div className="slider_actions">
                    <i
                      role="button"
                      className={`fa fa-thumbs-up ${loadingLike && isLike && 'active'}`}
                      onClick={this.like}
                    />
                    <i
                      role="button"
                      className={`fa fa-thumbs-down mt-1 ${loadingLike && !isLike && 'active'}`}
                      onClick={this.dislike}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { ...CourseActions, ...UserActions.ProfileActions },
    dispatch
  );

export default connect(null, mapDispatchToProps)(CardCurso);
