import {gateway} from '../utils/api'

export const fetchOnboardingPages = async () => {
  return gateway.get('/onboarding/steps')
}

export const saveOnboardingSteps = async (data) => {
  const url = "/onboarding/steps"
  return gateway.post(url, data)
}

export const fetchSelectType = async (group, text = '') => {
  return gateway.get(`onboarding/select-options?group=${group}&text=${text}`)
}