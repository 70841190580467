import React, { memo, useMemo, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FaSpinner } from 'react-icons/fa';
import _ from 'lodash';

import history from '../../utils/history';
import Tooltip from '../Tooltip';
import NavBarSuperior from '../NavBarSuperior/NavBarSuperior';

import InfoHome from './InfoHome';
import LaneNewGroups from './LaneNewGroups';
import LaneBooks from './LaneBooks';
import LaneCourses from './LaneCourses';
import LaneEvents from './LaneEvents';
import LaneWarnings from './LaneWarnings';
import LaneList from './LaneList';

import CustomContentLoader from './CustomContentLoader';
import { gateway } from '../../utils/api';
import { WrapperInfiniteScroll, LoadingWrapper, BodyDinamicBackgroundAndTextColor } from './styles';

import { Creators as CourseActions } from '../../store/ducks/newCourse/actions';
import { CheckAddressOnboarding } from '../CheckAddressOnboarding';

const lanesComponentsDictionary = {
  LANE_COURSES: LaneCourses,
  LANE_IN_PROGRESS: LaneCourses,
  LANE_CLASS_COURSE: LaneCourses,
  LANE_LAST_VIEWED: LaneCourses,
  LANE_BOOKS: LaneBooks,
  LANE_BOOKS_DEFAULT: LaneBooks,
  LANE_SOCIAL_GROUPS: LaneNewGroups,
  LANE_EVENTS: LaneEvents,
  LANE_WARNINGS: LaneWarnings,
  LANE_CLASS: LaneList,
  LANE_TOPICS_BY_INTEREST: LaneList,
  LANE_TOP_COURSES_WATCHED: LaneCourses
};

const fetchHomeData = async () => {
  const homeCrCode = localStorage.getItem('homeCrCode');
  const defaultHomeCrCode = localStorage.getItem('defaultHomeCrCode');

  const url = homeCrCode
    ? `webbff/lit_app_web/home/v2/?homeCrCode=${homeCrCode}`
    : 'webbff/lit_app_web/home/v2';

  const response = await gateway.get(url);

  if (!defaultHomeCrCode) {
    localStorage.setItem('defaultHomeCrCode', response.homeCode);
  }

  return _.get(response, 'lanes', []);
};

const Home = () => {
  const settings = JSON.parse(localStorage.getItem("settings")) || null;
  const tenant = localStorage.getItem("tenant") || "";
  const homeCrCode = localStorage.getItem("defaultHomeCrCode") || "";
  const selectedClassCrCode = localStorage.getItem("selectedClassCrCode") || "";
  const [lanesComponents, setLanesComponents] = useState([]);
  const [showcaseBanners, setShowcaseBanners] = useState([]);
  const [items, setItems] = useState([]);
  const [loader, setLoader] = useState(null);
  const idProfile = localStorage.getItem('idProfile');

  const dispatch = useDispatch();
  const redirectToTenantData = useSelector(state => state.newCourse.redirectToTenantData);
  const { data: lanes, isLoading } = useQuery(`homeData-${idProfile}-${tenant}-${homeCrCode}-${selectedClassCrCode}`, fetchHomeData, {
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 1000 * 60 * 10,
    cacheTime: 1000 * 60 * 30, 
  });

  const getLaneFromObj = obj => {
    const type = _.get(obj, 'lane.type', null);

    if (type === 'VITRINE_DISPLAY') {
      const filtredDocsToWeb = obj.docs.filter(doc => doc.tags.includes('WEB'));
      setShowcaseBanners(filtredDocsToWeb);
    }

    const component = lanesComponentsDictionary[type];
    return component ? { component, show: true, data: obj } : null;
  };

  useEffect(() => {
    if (lanes) {
      setLanesComponents(lanes.map(getLaneFromObj).filter(Boolean));
    }
  }, [lanes]);



  const getAdditionalLanes = () => {
    const marginTop = 500;
    const marginBottom = 245;
    const lanesMinHeight = 124;
    const remainingSpace = window.innerHeight - marginTop - marginBottom;
    return Math.trunc(remainingSpace / lanesMinHeight);
  };

  const lanesMemoized = useMemo(() => {
    const lanesItems = [...lanesComponents];
    setItems(lanesItems.slice(0, 6 + getAdditionalLanes()));
    return lanesItems;
  }, [lanesComponents]);

  const getLoader = () => {
    if (isLoading) {
      return (
        <>
          {[...Array(2)].map((_, i) => (
            <CustomContentLoader key={i} tooltipText="Carregando descrição" title="Carregando Lista" />
          ))}
        </>
      );
    }

    if (items.length !== lanesMemoized.length) {
      return (
        <LoadingWrapper>
          <FaSpinner size={20} color="#27aae1" />
        </LoadingWrapper>
      );
    }
    return null;
  };

  const fetchMoreData = useCallback(() => {
    const moreItems = lanesMemoized.slice(items.length, items.length + 3);

    if (moreItems.length > 0) {
      setTimeout(() => {
        setItems(prevState => prevState.concat(moreItems));
        setLoader(getLoader());
      }, 1500);
    } else {
      setItems(prevState => prevState.concat(items.length));
    }
  }, [items]);

  useEffect(() => {
    setLoader(getLoader());
  }, [isLoading]);

  useEffect(() => {
    if (redirectToTenantData) {
      const { course, object, isSelectedTenant } = redirectToTenantData;
      if (isSelectedTenant && course) {
        dispatch(CourseActions.redirectToTenant(null));
        history.push({ pathname: `/curso-novo/${course}` }, { object });
      }
    }
  }, [redirectToTenantData]);

  return (
    <div id="home">
      <CheckAddressOnboarding />
      <Tooltip id="home" />
      <NavBarSuperior home reviewSmartBar />

      <BodyDinamicBackgroundAndTextColor
        backgroundColor={_.get(settings, `${tenant}.bodyBackgroundColor`, "")}
        textColor={_.get(settings, `${tenant}.bodyTextColor`, "")}
        style={{ overflowX: 'hidden', minHeight: '100vh', paddingBottom: 50 }}
      >
        <InfoHome showcaseBanners={showcaseBanners} />

        <WrapperInfiniteScroll>
          <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreData}
            hasMore
            loader={loader}
            scrollableTarget="scrollableDiv"
            scrollThreshold={0.5}
          >
            {!isLoading &&
              items.map((item, index) =>
                item.component ? <item.component key={index} data={item.data} /> : null
              )}
          </InfiniteScroll>
        </WrapperInfiniteScroll>
      </BodyDinamicBackgroundAndTextColor>
    </div>
  );
};

export default memo(Home);
