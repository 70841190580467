/* eslint-disable prefer-destructuring */
import _ from "lodash";
import { toast } from "react-toastify";
import { call, delay, fork, put, select, spawn } from "redux-saga/effects";
import api, { gateway } from "../../utils/api";
import { toastErrorAndWarningOptions } from '../../utils/functions';
import history from "../../utils/history";
import { Creators as CourseActions } from "../ducks/newCourse/actions";

function getObjectContent(response, crCode) {
  let selected = null;
  let defaultSelectedCourse = 0;
  if (response.children.length > 0 && crCode) {
    if (response.type === 'TRAIL') {
      response.children.forEach((course, index) => {
        if (course.children.length > 0) {
          course.children.forEach(uc => {
            uc.children.find(object => {
              if (object.code === crCode) {
                selected = object;
                defaultSelectedCourse = index;
              }
            });
          });
        } else if (!selected) {
          selected = response.children[0];
        }
      });
    } else {
      response.children.forEach(element => {
        if (element.children.length > 0) {
          element.children.find(childrenEl => {
            if (childrenEl.code === crCode) {
              selected = childrenEl;
            }
          });
        } else if (!selected) {
          selected = response.children[0];
        }
      });
    }

  } else if (response.children.length && !crCode) {
    response.children.forEach(element => {

      const isAbout = element.tags.some((item) => item === 'ABOUT')

      if (isAbout) {
        selected = element
      } else {
        selected = element.children.find((item) => item.type === 'OBJECT')
      }

    });
  }
  return {
    selected,
    defaultSelectedCourse
  };
}

function* courseProgress(response, idProfile, courseAccessed, courseSession) {
  try {
    let { code, courseCode, name, gradeAverage, courseGradeStatus, id } = response

    if (!courseCode) {
      courseCode = code;
    }
    const data = {
      id,
      courseCode,
      name,
      gradeAverage,
      courseGradeStatus,
      idProfile,
      courseSession
    }

    if (!courseAccessed) {
      try {
        yield call(gateway.post, '/webbff/lit_app_web/course_progress', data);
        localStorage.setItem('courseAccessed', true)
      } catch (error) {
        console.log('Route course_progress not accessed', error);
      }
    }
  } catch (error) {
    console.log('Erro function courseProgress', error);
  }
}

function* getSessionCourse(course, idProfile, courseAccessed) {
  let { code, courseCode } = course

  if (!courseCode) {
    courseCode = code
  }

  let session;

  const sessionStorage = localStorage.getItem('session')

  if (!sessionStorage) {
    const response = yield call(gateway.get, `/webbff/lit_app_web/course_progress/course_session/${courseCode}`);

    const { maxSession } = response;

    if (!maxSession) {
      session = 1;
      localStorage.setItem('session', 1);
    } else {
      session = maxSession + 1;
    }

    localStorage.setItem('session', session);

    yield put(CourseActions.setCourseSession(session));
    yield call(courseProgress, course, idProfile, courseAccessed, session);
  } else {
    session = sessionStorage;
    yield put(CourseActions.setCourseSession(session));
    yield call(courseProgress, course, idProfile, courseAccessed, session);
  }
}

function* fetchCourse(action) {
  const { course, refreshCourse } = action.payload; 
  const idCourse = course.id;
  const courseAccessed = localStorage.getItem("courseAccessed");

  try {
    if (!courseAccessed) {
      yield spawn(function* logAccess() {
        try {
          yield call(gateway.post, '/applicationlogs/courseaccesslogs/', { courseCode: idCourse });
          localStorage.setItem('courseAccessed', true);
        } catch (error) {
          console.log('Route not accessed', error);
        }
      });
    }

    const courseObject = _.get(history, "location.state.object", null);
    let selectedObject = null;
    let defaultSelectedCourse = 0;

    if (courseObject) {
      const { selected, defaultSelectedCourse: selectedCourse } = getObjectContent(course, courseObject);
      selectedObject = selected;
      defaultSelectedCourse = selectedCourse;
    } else {
      const { selected, defaultSelectedCourse: selectedCourse } = getObjectContent(course, course.lastDispatchedContentCode);
      selectedObject = selected;
      defaultSelectedCourse = selectedCourse;
    }

    if (!selectedObject) {
      selectedObject = course.children.find(item => _.get(item, "tags", []).includes("ABOUT")) || course.children[0];
    }
    console.log(course, 'fetch course content')
    yield put(CourseActions.getCourseSuccess(course));
    yield put(CourseActions.setCourseSelected(selectedObject));
    yield put(CourseActions.setRefreshCourse(false));
    yield put(CourseActions.setDefaulfSelectedCourse(defaultSelectedCourse));

    const openProgress = localStorage.getItem('openProgress');
    if (refreshCourse || openProgress) {
      yield put(CourseActions.setCourseToShowProgress(course));
    }

    const idProfile = localStorage.getItem("idProfile");
    yield call(getSessionCourse, course, idProfile, courseAccessed);

  } catch (err) {
    console.error("Erro ao processar curso:", err);
    if (err.response) {
      err.response = {
        ...err.response,
        data: {
          message: err.response.data.message || err.response.data.error,
          ...err.response.data
        }
      };
      if (err.response.status === 401) {
        toast.warn(err.response.data.message, toastErrorAndWarningOptions);
        history.push("/");
        return;
      }
      yield put(CourseActions.getCourseFailure(err.response));
    }
  }
}


export function* getCourse(action) {
  yield fork(fetchCourse, action);
}

export function* reloadCourse(action) {
  const { idCourse } = action.payload;

  try {
    const response = yield call(
      gateway.get,
      `/webbff/lit_app_web/course/${idCourse}`
    );

    yield put(CourseActions.getCourseSuccess(response));
  } catch (err) {
    if (("ERRRO NO GET DE CURSOS", err.response)) {
      err.response = {
        ...err.response,
        data: {
          message: err.response.data.message || err.response.data.error,
          ...err.response.data
        }
      };
      if (err.response.status === 401) {
        toast.warn(err.response.data.message, toastErrorAndWarningOptions);
        history.push("/");
        return;
      }
      yield put(CourseActions.getCourseFailure(err.response));
    }
  }
}

export function* getCourseProgress({ payload }) {
  try {
    const ra = localStorage.getItem("ra");
    const response = yield call(
      api.get,
      `getGetCountingObjects/${ra}/${payload.idCourse}/`
    );
    yield put(CourseActions.getCourseProgressSuccess(response));
  } catch (error) {
  }
}

export function* syncUserGradeByDiscipline({ payload }) {
  try {
    const state = yield select();
    const discipline = state.course.data.disciplines.find(
      item => item.idDiscipline === payload
    );
    if (!discipline.synchronized) {
      yield put(CourseActions.defineDisciplineAsSynchronizedAction(payload));
    }
  } catch (error) {
    yield put(CourseActions.defineDisciplineAsSynchronizedAction(payload));
  }
}

export function* getCourseCertificate({ payload }) {
  try {
    const courseCode = _.get(payload, 'courseCode');
    const response = yield call(
      gateway.get,
      `webbff/lit_app_web/course/${courseCode}/certificate`
    );

    yield put(CourseActions.getCourseCertificateSuccess({
      courseCode,
      response
    }));

  } catch (error) {
    yield put(CourseActions.getCourseCertificateFailure());
  }
}

